export function isSameDay(date1, date2 = new Date()) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

function addDays(date = new Date(), days = 1) {
  let newDay = new Date(date)
  newDay.setDate(newDay.getDate() + days)
  return newDay
}

export function dateRangesOverlap(start1, end1, start2, end2) {
  return (start1 < end2 && start2 < end1) || (start2 < end1 && start1 < end2)
}

export function dayInRange(day, start, end) {
  const validRange = start < end || isSameDay(start, end)
  if (!validRange) return false
  return (
    isSameDay(day, start) || isSameDay(day, end) || (day > start && day < end)
  )
}

/**
 * @returns {number} the number of calendar days between two dates
 */
export function getDaysBetween(date1, date2 = new Date(), absolute = true) {
  let date1Copy = new Date(date1)
  let date2Copy = new Date(date2)
  date1Copy.setHours(0, 0, 0, 0)
  date2Copy.setHours(0, 0, 0, 0)
  // if (date1Copy.getTime() === date2Copy.getTime()) {
  //   return 0
  // }
  let diff = absolute
    ? Math.abs(date1.getTime() - date2.getTime())
    : date2.getTime() - date1.getTime()
  return Math.round(diff / (1000 * 3600 * 24))
}

export function getUTCDate(string) {
  const d = new Date(string)
  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate())
}

export function getFullMonth(
  date = new Date(),
  { month = 'long' } = {},
  locale = 'en-US'
) {
  return new Intl.DateTimeFormat(locale, { month }).format(date)
}

export default function getArrayOfDays({
  referenceDay = new Date(),
  amount = 30,
} = {}) {
  if (amount === 0) return []

  let stopDate = addDays(referenceDay, amount)
  let days = []
  let goForward = amount > 0
  let day = new Date(referenceDay)
  while (!isSameDay(day, stopDate)) {
    day = addDays(day, goForward ? 1 : -1)
    days.push(day)
  }
  if (!goForward) days.reverse()
  return days
}

function getMostRecentMonday() {
  let today = new Date()
  today = new Date(today.getFullYear(), today.getUTCMonth(), today.getUTCDate())
  const dayOfWeek = today.getDay()
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
  const mostRecentPastMonday = new Date(today.setDate(diff))

  return mostRecentPastMonday
}

function workWeek(startingMonday) {
  const days = getArrayOfDays({ referenceDay: startingMonday, amount: 5 })
  return days
}

export function getWorkWeeks(numberOfWeeks = 8) {
  const mostRecentPastMonday = getMostRecentMonday()
  const workWeeks = []
  for (let i = 0; i < numberOfWeeks; i++) {
    const week = workWeek(addDays(mostRecentPastMonday, i * 7 - 1))
    workWeeks.push(week)
  }
  return workWeeks
}

/**
 * @param {dateString} string in format MM/DD/YYYY
 */
export function newDateFromString(dateString) {
  if (typeof dateString !== 'string') return null
  const parts = dateString.split('/')
  const formattedDate = new Date(parts[2], parts[0] - 1, parts[1])
  return formattedDate
}
