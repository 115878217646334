import { createContext, useState, useContext } from 'react'

export const ModalContext = createContext()

export default function ModalProvider({ children }) {
  const [modal, setModal] = useState(undefined)
  const modalData = {
    modal,
    setModal,
  }
  return (
    <ModalContext.Provider value={modalData}>{children}</ModalContext.Provider>
  )
}

export function useModal() {
  const context = useContext(ModalContext)
  return context
}
