import { useMemo, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useApi } from '../contexts/ApiProvider'
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Tag,
  HStack,
  Flex,
  Text,
  IconButton,
  useToast,
  useColorModeValue,
  Link,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Menu,
  MenuButton,
  Spacer,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { ArrowDownIcon, TriangleDownIcon } from '@chakra-ui/icons'
import JobSearch from '../components/JobSearch'

import { ANALYTICS, NotesCell, ANALYSTS } from './AAHub'
import Pagination from '../NewSheet/Pagination'
import { JobFormContext } from '../NewSheet/Job2'

export default function AAProjects() {
  let [searchParams, setSearchParams] = useSearchParams()
  // let group = searchParams.get('group') || null
  let page = searchParams.get('page') || 1
  let perPage = searchParams.get('per_page') || 20
  let search = searchParams.get('search') || ''
  const toast = useToast()

  let group = useMemo(() => searchParams.get('group') || null, [searchParams])
  let archived = useMemo(
    () =>
      searchParams.get('archived') !== null
        ? searchParams.get('archived').split(',')
        : ['true', 'false'],
    [searchParams]
  )
  let orderBy = useMemo(
    () =>
      searchParams.get('order_by') !== null
        ? searchParams.get('order_by').split(',')
        : ['startDate'],
    [searchParams]
  )
  let users = useMemo(
    () =>
      searchParams.get('users') !== null
        ? searchParams.get('users').split(',')
        : ANALYSTS.map(analyst => analyst.name),
    [searchParams]
  )
  const handleSearch = search => {
    searchParams.set('page', 1)
    if (search === '') {
      searchParams.delete(search)
    }
    searchParams.set('search', search)
    setSearchParams(searchParams)
  }

  let desc = searchParams.get('desc') || true
  const [jobs, setJobs] = useState(undefined)
  const [pagination, setPagination] = useState(undefined)
  const api = useApi()
  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const query = {
        page,
        per_page: perPage,
        archived,
        group,
        department: ['aa'],
        desc,
        order_by: orderBy,
        users,
        ...(search.length > 0
          ? {
              search,
            }
          : {}),
      }
      const response = await api.get('/jobs2', query)
      // setJobs(sortBy(response.items, j => sortBy(j, j => j?.aa?.join('/'))))
      setJobs(response.body.items)
      const { next, prev } = response.body._links

      let firstPage = prev ? new URLSearchParams(prev.split('?')[1]) : null
      firstPage?.set('page', 1)
      let lastPage =
        response.body._meta.total_pages !== response.body._meta.page && next
          ? new URLSearchParams(next.split('?')[1])
          : null
      lastPage?.set('page', response.body._meta.total_pages)

      setPagination({
        page: response.body._meta.page,
        perPage: response.body._meta.per_page,
        totalItems: response.body._meta.total_items,
        next: next ? new URLSearchParams(next.split('?')[1]) : null,
        prev: prev ? new URLSearchParams(prev.split('?')[1]) : null,
        firstPage,
        lastPage,
      })
      setLoading(false)
    })()
  }, [api, archived, desc, group, orderBy, page, perPage, search, users])

  const handleUpdate = async (update, i) => {
    const updatedKeys = Object.keys(update)
    const job = jobs[i]
    if (updatedKeys.every(key => `${update[key]}` === `${job[key]}`)) {
      console.log('Skipping update, no change.', { update, oldData: job })
      return
    }
    if (updatedKeys.includes('aaDeliverables')) {
      let includedFields = []
      ANALYTICS.forEach(analytic => {
        if (update.aaDeliverables.includes(analytic.name)) {
          if (!includedFields.includes(analytic.dueDate)) {
            includedFields.push(analytic.dueDate)
          }
          if (analytic?.design) {
            if (!includedFields.includes(analytic.design)) {
              includedFields.push(analytic.design)
            }
          }
        }
      })
    }
    setJobs(prev => [
      ...prev.slice(0, i),
      { ...prev[i], ...update },
      ...prev.slice(i + 1),
    ])
    console.log({ update })
    const response = await api.put(`/jobs/${job.jobNum}`, update)
    if (response.ok) {
      toast({
        title: `${job.jobNum} updated`,
        status: 'success',
        duration: 1500,
        isClosable: true,
        position: 'bottom-right',
      })
    }
  }

  const handleFilterUsers = value => {
    const page = searchParams.get('page') ?? '1'
    if (page !== '1') {
      searchParams.set('page', '1')
    }
    if (value.length === 0 || value.length === ANALYSTS.length) {
      searchParams.delete('users')
      setSearchParams(searchParams)
      return
    }
    searchParams.set('users', value.length > 0 ? value.sort().join(',') : '')
    setSearchParams(searchParams)
  }
  const fieldBackground = useColorModeValue('blackAlpha.50', 'whiteAlpha.50')
  const [loading, setLoading] = useState(false)
  return jobs !== undefined ? (
    <Box>
      <Pagination {...pagination} numItemsOnCurrentPage={jobs.length}>
        <JobSearch handleSubmit={handleSearch} />
      </Pagination>
      <Table size="sm" whiteSpace="nowrap">
        <Thead>
          <Tr>
            <Th>Job #</Th>
            <Th>
              <HStack>
                <Text>Launch</Text>
                <ArrowDownIcon width={4} />
                {/* <IconButton
                  size={'xs'}
                  icon={<ArrowDownIcon />}
                  width={4}
                  variant="ghost"
                /> */}
              </HStack>
            </Th>
            <Th>Client</Th>
            <Th>Analytics</Th>
            <Th>Project</Th>
            <Th>
              <Flex align="center">
                <Text>AA</Text>
                <Spacer />
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={IconButton}
                    icon={<TriangleDownIcon />}
                    size="xs"
                  />
                  <MenuList fontSize="sm">
                    <MenuOptionGroup
                      value={users}
                      onChange={handleFilterUsers}
                      // fontSize="lg"
                      title="Analyst"
                      type="checkbox"
                      textTransform="capitalize"
                      letterSpacing="normal"
                    >
                      {ANALYSTS.map((analyst, i) => (
                        <MenuItemOption
                          fontSize="xs"
                          value={analyst.name}
                          key={i}
                        >
                          {analyst.name}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </Th>
            <Th>Notes</Th>
          </Tr>
        </Thead>
        <Tbody {...(loading ? { filter: 'blur(1.5px)', opacity: 0.35 } : {})}>
          {jobs?.map((j, i) => (
            <JobFormContext.Provider value={j}>
              <Tr key={i}>
                <Td>
                  <Link
                    as={RouterLink}
                    to={`/status?search=${j.jobNum}&archived=true,false`}
                  >
                    {j.jobNum}
                  </Link>
                </Td>
                <Td>{j.startDate}</Td>
                <Td>{j.client}</Td>
                <Td>
                  <HStack spacing={1}>
                    {j.aaDeliverables?.split('/')?.map((analytic, a) => (
                      <Tag
                        size="sm"
                        key={a}
                        colorScheme={
                          ANALYTICS.find(ANALYTIC => ANALYTIC.name === analytic)
                            ?.color
                        }
                      >
                        {analytic}
                      </Tag>
                    ))}
                  </HStack>
                </Td>
                <Td
                  textOverflow="ellipsis"
                  overflow="hidden"
                  maxW="max(15vw, 100px)"
                >
                  {j.description}
                </Td>
                <Td>{j.aa?.join('/')}</Td>
                <Td>
                  <NotesCell
                    data={j.aaNotes}
                    type="text"
                    handleUpdate={newValue => {
                      handleUpdate({ aaNotes: newValue }, i)
                    }}
                    attribute="aaNotes"
                    departments={['aa']}
                    neverFade={false}
                    key={j.id}
                    version={j.version}
                    background={fieldBackground}
                    width="max(20vw, 50px)"
                  />
                  {/* {j.aaNotes} */}
                </Td>
              </Tr>
            </JobFormContext.Provider>
          ))}
        </Tbody>
      </Table>
    </Box>
  ) : (
    'loading'
  )
}
