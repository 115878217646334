import { useSearchParams } from 'react-router-dom'
import { useMemo, useEffect, useState, useContext, useRef } from 'react'
import { apiFetch } from '../auth/apiFetch'
import {
  Text,
  Box,
  Editable,
  EditablePreview,
  EditableInput,
  Stack,
  Table,
  Tbody,
  Link,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useToast,
  GridItem,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  EditableTextarea,
  useToken,
  Button,
  ButtonGroup,
  IconButton,
  Icon,
} from '@chakra-ui/react'
import { FaUndo } from 'react-icons/fa'
import { EditIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { Link as RouterLink } from 'react-router-dom'
import {
  AttributeCell,
  JobFormContext,
  ListCell,
  formatForInput,
  ListControls,
} from '../NewSheet/Job2'
import {
  dayInRange,
  getUTCDate,
  getWorkWeeks,
  isSameDay,
  newDateFromString,
} from '../utils/dates'
import { useUser } from '../contexts/UserProvider'
import { useApi } from '../contexts/ApiProvider'
export const ANALYSTS = [
  { name: 'Curtis', color: 'green' },
  { name: 'Jackie', color: 'cyan' },
  { name: 'Raman', color: 'yellow' },
  { name: 'Sonia', color: 'purple' },
  { name: 'Marcos', color: 'blue' },
  { name: 'Scott', color: 'red' },
  { name: 'Unassigned', color: 'gray' },
]
export const ANALYTICS = [
  { name: 'Segmentation', dueDate: 'segmentation', color: 'blue' },
  {
    name: 'Conjoint',
    dueDate: 'simulatorToRa',
    design: 'dcmDesign',
    color: 'red',
  },
  {
    name: 'MaxDiff',
    dueDate: 'otherAa',
    design: 'maxDiffDesign',
    color: 'orange',
  },
  {
    name: 'Augmented MaxDiff',
    dueDate: 'otherAa',
    design: 'maxDiffDesign',
    color: 'orange',
  },
  { name: 'Drivers', dueDate: 'drivers', color: 'green' },
  { name: 'Opportunity Scores', dueDate: 'otherAa' },
  { name: 'Typing Tool', dueDate: 'otherAa' },
  { name: 'Factor Analysis', dueDate: 'otherAa' },
  { name: 'Correlations', dueDate: 'otherAa' },
  { name: 'Miscellaneous', dueDate: 'otherAa' },
  { name: 'TURF', dueDate: 'otherAa' },
]
const ANALYTIC_FIELDS = [
  ...new Set(
    ANALYTICS.flatMap(analytic => {
      let fields = [analytic.dueDate]
      if (analytic.design) {
        fields.push(analytic.design)
      }
      return fields
    })
  ),
]

export function NotesCell({
  attribute,
  top,
  handleUpdate,
  version,
  departments,
  neverFade,
  ...props
}) {
  const job = useContext(JobFormContext)
  const data = job?.[attribute]
  const [value, setValue] = useState(data ?? '')

  const handleChange = nextValue => setValue(nextValue)
  const ref = useRef(null)
  const handleBlur = () => {
    handleUpdate(value)
  }
  const { user } = useUser()
  const userInAnyAttributeDepartments =
    Array.isArray(departments) &&
    departments.some(
      department => user?.departments && user?.departments.includes(department)
    )
  const isAdmin =
    Array.isArray(user?.role) && user.role.some(role => role >= 100)

  const isDepartmentField =
    (user?.departments && user?.departments.includes(attribute)) ||
    userInAnyAttributeDepartments
  const isDisabled = !isAdmin && !isDepartmentField
  useEffect(() => {
    // console.log(`Rerender ListCell ${job.jobNum} ${attribute}`, { data, value })
    setValue(formatForInput(data, 'list'))
  }, [data, job?.version])

  return (
    <GridItem
      as={Flex}
      // alignItems="center"
      pl={1}
      // justify="center"
      {...props}
      // fontSize="12px"
    >
      <Popover>
        <PopoverTrigger>
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize="11px"
            textAlign
          >
            {data && data.split('\n')[0]}
            <IconButton size="xs" icon={<EditIcon />} variant="ghost" />
          </Text>
        </PopoverTrigger>
        <PopoverContent>
          <Editable
            isDisabled={isDisabled}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            onSubmit={() => {
              ref.current.innerText = value ?? ''
            }}
            as="span"
            size="xs"
            placeholder={`Enter note here`}
            w={'100%'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            opacity={isDisabled && !neverFade ? 0.5 : 1}
          >
            <EditablePreview w={'100%'} ref={ref} whiteSpace={'pre-wrap'} />
            <EditableTextarea
              // as={EditableInput}
              size="xs"
              _focus={{
                outline: '2px solid transparent',
                outlineOffset: '2px',
              }}
              ref={ref}
            />
            {/* <EditableInput
              _focus={{
                outline: '2px solid transparent',
                outlineOffset: '2px',
              }}
            /> */}
          </Editable>
        </PopoverContent>
      </Popover>
    </GridItem>
  )
}

export function AnalyticsCell({
  attribute,
  top,
  handleUpdate,
  version,
  departments,
  neverFade,
  ...props
}) {
  const job = useContext(JobFormContext)
  const data = job?.[attribute]
  const [value, setValue] = useState(formatForInput(data, 'list'))

  const handleChange = nextValue => setValue(nextValue)
  const inputRef = useRef(null)
  const handleBlur = newValue => handleUpdate(newValue)
  const options = ANALYTICS.map(({ name }) => name)
  const { user } = useUser()
  const userInAnyAttributeDepartments =
    Array.isArray(departments) &&
    departments.some(
      department => user?.departments && user?.departments.includes(department)
    )
  const isAdmin =
    Array.isArray(user?.role) && user.role.some(role => role >= 100)

  const isDepartmentField =
    (user?.departments && user?.departments.includes(attribute)) ||
    userInAnyAttributeDepartments
  const isDisabled = !isAdmin && !isDepartmentField
  useEffect(() => {
    // console.log(`Rerender ListCell ${job.jobNum} ${attribute}`, { data, value })
    setValue(formatForInput(data, 'list'))
  }, [data, job?.version])

  return (
    <GridItem
      as={Flex}
      alignItems="center"
      pl={1}
      justify="center"
      {...props}
      fontSize="12px"
    >
      <Editable
        fontSize={props?.fontSize}
        isDisabled={isDisabled}
        value={value}
        onChange={handleChange}
        as="span"
        placeholder={'    '}
        w={'100%'}
        whiteSpace={'nowrap'}
        textOverflow={'ellipsis'}
        opacity={isAdmin || isDepartmentField ? 1 : 0.55}
        sx={{ ...(isDisabled ? { pointerEvents: 'none' } : {}) }}
        onMouseDown={ev => {
          if (isDisabled) {
            ev.preventDefault()
            ev.stopPropagation()
          }
        }}
        onEdit={() => {
          if (!inputRef.current) return
          inputRef.current.focus()
        }}
        onSubmit={() => {
          if (!inputRef.current) return
          inputRef.current.blur()
        }}
      >
        <EditablePreview w={'full'} p={0} whiteSpace={'pre'} />
        {isDisabled ? (
          <>
            <EditableInput />
          </>
        ) : (
          <>
            <EditableInput display={'none'} />
            <ListControls
              display={props?.display}
              data={data}
              value={value}
              setValue={setValue}
              handleBlur={handleBlur}
              // setSelectedItems={setSelectedItems}
              options={options}
              inputRef={inputRef}
              top={top}
            />
          </>
        )}
      </Editable>
    </GridItem>
  )
}

function JobRow({
  job: jobData,
  isMultiRow,
  isRollUpRow,
  analystColor,
  setJobs,
  workWeeks,
  daysOff,
  setDaysOff,
  analystName,
  hoverDay,
  setHoverDay,
  isFirstRow,
  isLastRow,
  ...props
}) {
  const { token, logout } = useUser()
  const toast = useToast()
  const [job, setJob] = useState(jobData)
  const [prevJob, setPrevJob] = useState(jobData)
  // const jobFormData = useMemo(() => job, [job])

  const handleUpdate = update => {
    const updatedKeys = Object.keys(update)
    if (updatedKeys.every(key => `${update[key]}` === `${job[key]}`)) {
      console.log('Skipping update, no change.', { update, oldData: job })
      return
    }
    if (updatedKeys.includes('aaDeliverables')) {
      let includedFields = []
      ANALYTICS.forEach(analytic => {
        if (update.aaDeliverables.includes(analytic.name)) {
          if (!includedFields.includes(analytic.dueDate)) {
            includedFields.push(analytic.dueDate)
          }
          if (analytic?.design) {
            if (!includedFields.includes(analytic.design)) {
              includedFields.push(analytic.design)
            }
          }
        }
      })
      const invalidFields = ANALYTIC_FIELDS.filter(
        field => !includedFields.includes(field)
      )
      invalidFields.forEach(field => {
        update[field] = null
        update[`${field}_completed`] = false
      })
    }
    setJob({ ...job, ...update })
    console.log({ update })
    apiFetch(`/api/jobs/${jobData.jobNum}`, token, logout, {
      method: 'PUT',
      data: update,
    })
      .then(response => {
        if (response?.error) {
        } else {
          console.log('Updated entry', response)
          toast({
            title: `${jobData.jobNum} updated`,
            status: 'success',
            duration: 1500,
            isClosable: true,
            position: 'bottom-right',
          })
          setPrevJob(response)
          setJobs(prevJobs => {
            const matchingJobIndex = prevJobs.findIndex(
              prevJob => prevJob.jobNum === job.jobNum
            )
            const newJobs = [
              ...prevJobs.slice(0, matchingJobIndex),
              response,
              ...prevJobs.slice(matchingJobIndex + 1),
            ]
            console.log({ newJobs, prevJobs, matchingJobIndex })
            return newJobs
          })
          // setJobs(prevJobs => {
          //   const matchingJob = prevJobs.find(
          //     idbJob => idbJob.id === response.id
          //   )
          //   if (matchingJob === undefined) {
          //     return [...prevJobs, response]
          //   }
          //   return prevJobs
          // })
        }
      })
      .catch(error => {
        console.log({ error })
        debugger
        setJob(prevJob)
        let {
          cause: { message: description },
        } = error
        if (!description) {
          description = error?.message
        }
        const title =
          error?.message && error.message.includes('403')
            ? 'Only Betsy 🤷‍♀️'
            : `${jobData.jobNum} Update Failed`
        console.log({ description })
        toast({
          title,
          description,
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        })
      })
  }

  const fieldBackground = useColorModeValue('blackAlpha.50', 'whiteAlpha.50')
  const blankBackground = useColorModeValue('gray.50', 'gray.800')

  const { design: designAttr, dueDate: dueAttr } = props
  const designDate = newDateFromString(designAttr ? job[designAttr] : null)
  const dueDate = newDateFromString(dueAttr ? job[dueAttr] : null)
  const dataDate = newDateFromString(job['fileToAa'])
  const transparentBgColor = useColorModeValue('blackAlpha', 'whiteAlpha')
  const backgroundIntensity = useColorModeValue(200, 600)
  const hoverBgIntensity = useColorModeValue(400, 300)
  const isDayBackgroundIntensity = useColorModeValue(500, 400)
  const gray300 = useToken('colors', 'gray.300')
  const transparentBorderColor = useToken(
    'colors',
    useColorModeValue('whiteAlpha.400', 'blackAlpha.50')
  )
  const hoverBorderColor = useToken(
    'colors',
    `${analystColor}.${hoverBgIntensity}`
  )
  const hoverBorderStyle = `2px solid ${hoverBorderColor}`

  const dayOffBgGradient = `repeating-linear-gradient(135deg, transparent, transparent, ${transparentBgColor}.${backgroundIntensity} 3px, ${transparentBgColor}.${backgroundIntensity} 6px)`
  // const dayOffTextColor = useColorModeValue('blackAlpha.300', 'whiteAlpha.600')
  const handleDayClick = clickedDate => {
    const existingDayOff = daysOff.find(
      dayOff =>
        dayOff.user.name_for_status === analystName &&
        isSameDay(dayOff.date, clickedDate)
    )
    if (existingDayOff) {
      if (existingDayOff.hours !== 8) {
        setDaysOff(prevDaysOff =>
          prevDaysOff.filter(dayOff => {
            return (
              dayOff.user.name_for_status !== analystName ||
              !isSameDay(dayOff.date, existingDayOff.date)
            )
          })
        )
      } else {
        const existingDayOffIndex = daysOff.findIndex(
          dayOff =>
            dayOff.user.name_for_status === analystName &&
            isSameDay(dayOff.date, clickedDate)
        )
        setDaysOff(prevDaysOff => [
          ...prevDaysOff.slice(0, existingDayOffIndex),
          { ...existingDayOff, hours: 4 },
          ...prevDaysOff.slice(existingDayOffIndex + 1),
        ])
      }
    } else {
      setDaysOff(prevDaysOff => [
        ...prevDaysOff,
        {
          user: { name_for_status: analystName },
          date: clickedDate,
          hours: 8,
        },
      ])
    }
  }

  return (
    <JobFormContext.Provider value={jobData}>
      <Tr {...props}>
        {isMultiRow ? (
          <Td colSpan={4} background={blankBackground} />
        ) : (
          <Td fontSize="xs">
            <ListCell
              neverFade={false}
              key={job.id}
              type="list"
              attribute="aa"
              departments={['aa']}
              data={job.aa}
              handleUpdate={newValue => {
                handleUpdate({ aa: newValue })
              }}
              colSpan={1}
              width="94px"
              background={fieldBackground}
            />
          </Td>
        )}
        <Td display={isMultiRow ? 'none' : null}>
          <Link as={RouterLink} to={`/status?search=${job.jobNum}`}>
            {job.jobNum}
          </Link>
        </Td>
        <Td
          textOverflow="ellipses"
          overflow="hidden"
          display={isMultiRow ? 'none' : null}
        >
          <Tooltip
            placement="left"
            hasArrow
            label={`${job.client} - ${job.description}`}
          >
            <Text
              maxW="max(15vw, 100px)"
              overflow="hidden"
              textOverflow={'ellipsis'}
            >
              <b>{job.client}</b>
              {' ' + job.description}
            </Text>
          </Tooltip>
        </Td>
        <Td display={isMultiRow ? 'none' : null}>{job.research.join('/')}</Td>
        {isMultiRow ? (
          <Td fontWeight={600} textAlign="center">
            {props.analytic}
          </Td>
        ) : (
          <Td fontSize="xs">
            <AnalyticsCell
              neverFade={false}
              // key={job.id}
              attribute="aaDeliverables"
              departments={['aa']}
              data={job.aa}
              handleUpdate={newValue => {
                handleUpdate({ aaDeliverables: newValue })
              }}
              colSpan={1}
              // width="94px"
              background={fieldBackground}
            />
          </Td>
        )}

        <Td fontSize="xs">
          {isMultiRow ? null : (
            <NotesCell
              data={job.aaNotes}
              type="text"
              handleUpdate={newValue => {
                handleUpdate({ aaNotes: newValue })
              }}
              attribute="aaNotes"
              departments={['aa']}
              neverFade={false}
              key={job.id}
              version={job.version}
              background={fieldBackground}
              width="max(20vw, 50px)"
            />
            // <Popover>
            //   <PopoverTrigger>
            //     <Text>
            //       {job.aaNotes}{' '}
            //       <IconButton size="xs" icon={<EditIcon />} variant="ghost" />
            //     </Text>
            //   </PopoverTrigger>
            //   <PopoverContent>
            //     <Editable value={job.aaNotes}>
            //       <EditablePreview />
            //       <Textarea as={EditableInput} />
            //     </Editable>
            //     <PopoverFooter>
            //       <ButtonGroup size="sm">
            //         <Button variant="outline">Cancel</Button>
            //       </ButtonGroup>
            //     </PopoverFooter>
            //   </PopoverContent>
            // </Popover>
          )}
        </Td>
        <Td>
          {isRollUpRow || !props.design || !props.analytic ? null : (
            <AttributeCell
              data={job[props.design]}
              completed={job[`${props.design}_completed`]}
              type="date"
              handleUpdate={newValue => {
                handleUpdate({ [props.design]: newValue })
              }}
              toggleCompleted={() =>
                handleUpdate({
                  [`${props.design}_completed`]:
                    !job[`${props.design}_completed`],
                })
              }
              attribute={props.design}
              departments={['aa']}
              neverFade={false}
              key={job.id}
              version={job.version}
              background={fieldBackground}
              width="60px"
            />
          )}
        </Td>
        <Td fontSize="xs">
          {isMultiRow ? null : (
            <AttributeCell
              data={job.startDate}
              completed={job.startDate_completed}
              type="date"
              handleUpdate={newValue => {
                handleUpdate({ startDate: newValue })
              }}
              toggleCompleted={() =>
                handleUpdate({
                  startDate_completed: !job.startDate_completed,
                })
              }
              attribute="startDate"
              departments={[]}
              neverFade={false}
              key={job.id}
              version={job.version}
            />
          )}
        </Td>
        <Td fontSize="xs">
          {isMultiRow ? null : (
            <AttributeCell
              data={job.fileToAa}
              completed={job.fileToAa_completed}
              type="date"
              handleUpdate={newValue => {
                handleUpdate({ fileToAa: newValue })
              }}
              toggleCompleted={() =>
                handleUpdate({
                  fileToAa_completed: !job.fileToAa_completed,
                })
              }
              attribute="fileToAa"
              departments={['aa']}
              neverFade={false}
              key={job.id}
              version={job.version}
              background={fieldBackground}
              width="60px"
            />
          )}
        </Td>
        <Td fontSize="xs">
          {isRollUpRow || !props.analytic ? null : (
            <AttributeCell
              data={job[props.dueDate]}
              completed={job[`${props.dueDate}_completed`]}
              type="date"
              handleUpdate={newValue => {
                handleUpdate({ [props.dueDate]: newValue })
              }}
              toggleCompleted={() =>
                handleUpdate({
                  [`${props.dueDate}_completed`]:
                    !job[`${props.dueDate}_completed`],
                })
              }
              attribute={props.dueDate}
              departments={['aa']}
              neverFade={false}
              key={job.id}
              version={job.version}
              background={fieldBackground}
              width="60px"
            />
          )}
        </Td>
        {workWeeks.flatMap((week, w) =>
          week.map((day, d) => {
            const style = {}
            if (d === 0) {
              style.borderLeft = `1px solid ${gray300}`
            } else {
              style.borderLeft = `1px solid ${transparentBorderColor}`
            }
            const isInDesignStage =
              designDate !== null && dayInRange(day, week[0], designDate)
            const isInActiveStage =
              dataDate !== null &&
              dueDate !== null &&
              dayInRange(day, dataDate, dueDate)
            const dayOff = daysOff.find(
              dayOff =>
                isSameDay(day, dayOff.date) &&
                dayOff.user.name_for_status === analystName
            )
            const isLastDay =
              workWeeks.length === 8 &&
              d === week.length - 1 &&
              w === workWeeks.length - 1
            // prevents hover effect on last day of 8 week view when more button is shown
            const isHoverDay =
              !isLastDay &&
              analystName !== 'Unassigned' &&
              hoverDay &&
              (hoverDay.analystName === analystName ||
                hoverDay.analystName === 'ALL') &&
              isSameDay(day, hoverDay?.day)

            if (dayOff) {
              style.bgGradient = dayOffBgGradient
              style.backgroundSize = `${(dayOff.hours / 8) * 100}% 100%`
              style.backgroundRepeat = 'no-repeat'
            }
            if (isHoverDay) {
              style.borderLeft = hoverBorderStyle
              style.borderRight = hoverBorderStyle

              if (isFirstRow) {
                style.borderTop = hoverBorderStyle
              }
              if (isLastRow) {
                style.borderBottom = hoverBorderStyle
              }
              // style.borderColor = `${analystColor}.${hoverBgIntensity}`
            }
            if (isInDesignStage) {
              if (isSameDay(day, designDate)) {
                style.background = `${transparentBgColor}.400`
              } else if (day < designDate) {
                style.borderTop = `1px solid red`
                style.borderBottom = `1px solid red`
                style.bgColor = `${transparentBgColor}.200`
              }
            } else if (isInActiveStage) {
              if (isSameDay(day, dueDate)) {
                style.bgColor = `${analystColor}.${isDayBackgroundIntensity}`
              } else if (day < dueDate && day >= dataDate) {
                style.bgColor = `${analystColor}.${backgroundIntensity}`
              }
            }
            return (
              <Td
                key={`${w}-${d}`}
                {...style}
                textAlign="center"
                onMouseEnter={() => setHoverDay({ day: day, analystName })}
                onMouseLeave={() => setHoverDay()}
                onClick={() => handleDayClick(day)}
                _hover={{
                  // border: '2px solid',
                  // borderColor: `${analystColor}.${hoverBgIntensity}`,
                  cursor: 'pointer',
                }}
              >
                {/* {dayOff
                  ? dayOff.hours === 8
                    ? 1
                    : (dayOff.hours / 8).toFixed(1)
                  : null} */}
              </Td>
            )
          })
        )}
      </Tr>
    </JobFormContext.Provider>
  )
}

export default function AAHub() {
  let [searchParams] = useSearchParams()
  // let group = searchParams.get('group') || null
  let page = searchParams.get('page') || 1
  let perPage = searchParams.get('per_page') || 100
  let search = searchParams.get('search') || ''

  let group = useMemo(() => searchParams.get('group') || null, [searchParams])
  let archived = useMemo(
    () =>
      searchParams.get('archived') !== null
        ? searchParams.get('archived').split(',')
        : ['false'],
    [searchParams]
  )

  const iconButtonStyles = useColorModeValue(
    {
      _hover: { background: 'whiteAlpha.800' },
      background: 'whiteAlpha.600',
    },
    {
      _hover: { background: 'blackAlpha.600' },
      background: 'blackAlpha.300',
    }
  )
  const { logout, token } = useUser()
  const [jobs, setJobs] = useState(undefined)
  const api = useApi()
  const [depDaysOff, setDepDaysOff] = useState(undefined)
  const [prevDepDaysOff, setPrevDepDaysOff] = useState(undefined)
  const [hoverDay, setHoverDay] = useState()
  const [numberOfWeeks, setNumberOfWeeks] = useState(8)
  useEffect(() => {
    ;(async () => {
      const queryString = new URLSearchParams({
        page,
        per_page: perPage,
        archived,
        group,
        department: ['aa'],
        aa_done: false,
        ...(search.length > 0
          ? {
              search,
            }
          : {}),
      }).toString()
      const endpoint = `/api/jobs2?${queryString}`
      const response = await apiFetch(endpoint, token, logout)
      // setJobs(sortBy(response.items, j => sortBy(j, j => j?.aa?.join('/'))))
      console.log({ response })
      setJobs(response.items)
    })()
  }, [archived, group, logout, page, perPage, search, token])
  useEffect(() => {
    ;(async () => {
      const response = await api.get('/users/department/aa/days_off')
      if (response.ok) {
        const newDepDaysOff = response.body.map(day => ({
          ...day,
          date: getUTCDate(day.date),
        }))
        setDepDaysOff(newDepDaysOff)
        setPrevDepDaysOff(newDepDaysOff)
      }
    })()
  }, [api])
  const daysOffChanges = useMemo(() => {
    const changes = { add: [], remove: [], change: [], hasChanged: false }

    // easy checks for no changes
    if (
      prevDepDaysOff === undefined ||
      depDaysOff === undefined ||
      JSON.stringify(prevDepDaysOff) === JSON.stringify(depDaysOff)
    ) {
      return changes
    }

    // check for changes
    depDaysOff.forEach(dayOff => {
      const prevDayOff = prevDepDaysOff.find(
        prevDayOff =>
          prevDayOff.user.name_for_status === dayOff.user.name_for_status &&
          isSameDay(prevDayOff.date, dayOff.date)
      )
      if (prevDayOff === undefined) {
        // new day off
        changes.add.push(dayOff)
      } else if (prevDayOff.hours !== dayOff.hours) {
        // modified day off
        changes.change.push(dayOff)
      }
    })
    prevDepDaysOff.forEach(prevDayOff => {
      // removed day off
      const dayOff = depDaysOff.find(
        dayOff =>
          dayOff.user.name_for_status === prevDayOff.user.name_for_status &&
          isSameDay(dayOff.date, prevDayOff.date)
      )
      if (dayOff === undefined) {
        changes.remove.push(prevDayOff)
      }
    })
    changes.hasChanged =
      changes.add.length > 0 ||
      changes.remove.length > 0 ||
      changes.change.length > 0
    return changes
  }, [depDaysOff, prevDepDaysOff])

  const handleSubmitDaysOffChanges = async () => {
    console.log({ daysOffChanges })
    const { add, remove, change } = daysOffChanges
    const response = await api.post('/users/department/aa/days_off', {
      add: add.map(day => ({
        ...day,
        date: day.date.toISOString().split('T')[0],
      })),
      remove: remove.map(day => ({
        ...day,
        date: day.date.toISOString().split('T')[0],
      })),
      change: change.map(day => ({
        ...day,
        date: day.date.toISOString().split('T')[0],
      })),
    })
    if (response.ok) {
      const newDepDaysOff = response.body.map(day => ({
        ...day,
        date: getUTCDate(day.date),
      }))
      setDepDaysOff(newDepDaysOff)
      setPrevDepDaysOff(newDepDaysOff)
    }
  }
  const colorIntensity = useColorModeValue(900, 50)
  const backgroundIntensity = useColorModeValue(50, 900)
  const borderColor = useColorModeValue('gray.100', 'whiteAlpha.300')
  const workWeeks = getWorkWeeks(numberOfWeeks)
  const handleDayHeaderClick = clickedDate => {
    const existingDayOff = depDaysOff.find(dayOff =>
      isSameDay(dayOff.date, clickedDate)
    )
    if (existingDayOff) {
      if (existingDayOff.hours !== 8) {
        // Remove all days off for clickedDate
        setDepDaysOff(prevDaysOff =>
          prevDaysOff.filter(dayOff => !isSameDay(dayOff.date, clickedDate))
        )
      } else {
        // Set all analysts to half day
        setDepDaysOff(prevDaysOff => {
          const newDaysOff = []
          const analystsDone = []
          prevDaysOff.forEach(dayOff => {
            if (isSameDay(dayOff.date, clickedDate)) {
              newDaysOff.push({
                ...dayOff,
                hours: 4,
              })
              analystsDone.push(dayOff.user.name_for_status)
            } else {
              newDaysOff.push(dayOff)
            }
          })
          const analystsRemaining = ANALYSTS.filter(
            analyst => !analystsDone.includes(analyst.name)
          )
          analystsRemaining.forEach(analyst => {
            newDaysOff.push({
              user: { name_for_status: analyst.name },
              date: clickedDate,
              hours: 4,
            })
          })
          return newDaysOff
        })
      }
    } else {
      // set day off for all analysts
      setDepDaysOff(prevDaysOff => {
        const newDaysOff = []
        const analystsDone = []
        prevDaysOff.forEach(dayOff => {
          if (isSameDay(dayOff.date, clickedDate)) {
            newDaysOff.push({
              ...dayOff,
              hours: 8,
            })
            analystsDone.push(dayOff.user.name_for_status)
          } else {
            newDaysOff.push(dayOff)
          }
        })
        const analystsRemaining = ANALYSTS.filter(
          analyst => !analystsDone.includes(analyst.name)
        )
        analystsRemaining.forEach(analyst => {
          newDaysOff.push({
            user: { name_for_status: analyst.name },
            date: clickedDate,
            hours: 8,
          })
        })
        return newDaysOff
      })
    }
  }
  return jobs !== undefined && depDaysOff !== undefined ? (
    <Box>
      <Stack spacing={0}>
        <Table size="xs" className="sticky-header" whiteSpace="nowrap">
          <Thead>
            <Tr>
              <Th className="blank-cell" />
              <Th className="blank-cell" />
              <Th className="blank-cell" />
              <Th className="blank-cell" />
              <Th className="blank-cell" />
              <Th className="blank-cell" />
              <Th className="blank-cell" />
              <Th className="blank-cell" />
              <Th className="blank-cell" />
              <Th className="blank-cell" />
              {workWeeks.flatMap((week, w) =>
                week.map((day, d) => (
                  <Th
                    className="gantt"
                    key={`${w}-${d}`}
                    borderRight={d === 4 ? '1px solid #CBD5E0' : null}
                    borderColor={borderColor}
                    onMouseEnter={() =>
                      setHoverDay({ day: day, analystName: 'ALL' })
                    }
                    onMouseLeave={() => setHoverDay()}
                    _hover={{
                      cursor: 'pointer',
                    }}
                    onClick={ev => handleDayHeaderClick(day)}
                  >
                    {day.toLocaleDateString(undefined, {
                      weekday: 'short',
                    })}

                    {/* {day.getMonth() + 1}/{day.getDate()} */}
                  </Th>
                ))
              )}
            </Tr>
            <Tr>
              <Th>AA Lead</Th>
              <Th>Job Number</Th>
              <Th>Project</Th>
              <Th>RA</Th>
              <Th>Analytics</Th>
              <Th>Notes</Th>
              <Th>Design</Th>
              <Th>SL</Th>
              <Th>Data</Th>
              <Th>Due Date</Th>
              {workWeeks.flatMap((week, w) =>
                week.map((day, d) => (
                  <Th
                    className="gantt"
                    key={`${w}-${d}`}
                    borderRight={d === 4 ? '1px solid #CBD5E0' : null}
                    borderColor={borderColor}
                    onMouseEnter={() =>
                      setHoverDay({ day: day, analystName: 'ALL' })
                    }
                    onMouseLeave={() => setHoverDay()}
                    onClick={ev => handleDayHeaderClick(day)}
                    _hover={{
                      cursor: 'pointer',
                    }}
                  >
                    {day.getMonth() + 1}/{day.getDate()}
                    {d === week.length - 1 && w === workWeeks.length - 1 && (
                      <IconButton
                        position="absolute"
                        right={'7px'}
                        top={'40px'}
                        height={'calc(100vh - 145px)'}
                        size="xs"
                        display={numberOfWeeks === 52 ? 'none' : 'flex'}
                        onClick={ev => {
                          ev.stopPropagation()
                          setNumberOfWeeks(52)
                        }}
                        icon={<ArrowRightIcon />}
                        aria-label="Expand to 52 weeks"
                        colorScheme="pink"
                      />
                    )}
                  </Th>
                ))
              )}
            </Tr>
          </Thead>
          <Tbody>
            {ANALYSTS.map(analyst => {
              const analystJobs = jobs.filter(job =>
                job.aa.includes(analyst.name)
              )
              return analystJobs.map((job, j) => {
                let analytics = job.aaDeliverables
                if (analytics) {
                  analytics = analytics.split('/')
                  if (analytics.length > 1) {
                    analytics = [null, ...analytics]
                  }
                  return analytics.map((analytic, a) => (
                    <JobRow
                      // key={`${analyst}-${job.id}-${a}`}
                      key={`${analyst.name}-${j}-${a}`}
                      background={`${analyst.color}.${backgroundIntensity}`}
                      color={`${analyst.color}.${colorIntensity}`}
                      job={job}
                      analytic={analytic}
                      dueDate={
                        ANALYTICS.find(({ name }) => name === analytic)?.dueDate
                      }
                      design={
                        ANALYTICS.find(({ name }) => name === analytic)?.design
                      }
                      isMultiRow={a >= 1}
                      isRollUpRow={a === 0 && analytics.length > 1}
                      setJobs={setJobs}
                      workWeeks={workWeeks}
                      daysOff={depDaysOff}
                      setDaysOff={setDepDaysOff}
                      hoverDay={hoverDay}
                      analystColor={analyst.color}
                      analystName={analyst.name}
                      setHoverDay={setHoverDay}
                      isFirstRow={j + a === 0}
                      isLastRow={
                        j === analystJobs.length - 1 &&
                        a === analytics.length - 1
                      }
                    />
                  ))
                }
                return (
                  <JobRow
                    key={job.id}
                    background={`${analyst.color}.${backgroundIntensity}`}
                    color={`${analyst.color}.${colorIntensity}`}
                    job={job}
                    setJobs={setJobs}
                    workWeeks={workWeeks}
                    daysOff={depDaysOff}
                    setDaysOff={setDepDaysOff}
                    analystColor={analyst.color}
                    analystName={analyst.name}
                    setHoverDay={setHoverDay}
                    isFirstRow={j === 0}
                    isLastRow={j === analystJobs.length - 1}
                  />
                )
              })
            })}
          </Tbody>
        </Table>
        <ButtonGroup
          position="fixed"
          right="25px"
          bottom="25px"
          display={daysOffChanges.hasChanged ? 'flex' : 'none'}
          isAttached
          border="1px solid"
          borderColor="gray.200"
          borderRadius="6px"
        >
          <Button colorScheme="green" onClick={handleSubmitDaysOffChanges}>
            Submit Time Off
          </Button>
          <IconButton
            colorScheme="red"
            aria-label="Undo Changes"
            icon={<Icon as={FaUndo} />}
            variant="ghost"
            backdropFilter="blur(10px) saturate(100%)"
            onClick={() => setDepDaysOff(prevDepDaysOff)}
            {...iconButtonStyles}
          />
        </ButtonGroup>
      </Stack>
    </Box>
  ) : (
    'loading...'
  )
}
