import React from 'react'
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { titleCase } from '../utils/strings'
import { useUser } from '../contexts/UserProvider'
import { apiFetch } from '../auth/apiFetch'
import { sortBy } from 'lodash'
import { useUsers } from '../contexts/UsersProvider'
import { useModal } from '../contexts/ModalProvider'

export default function UserModal() {
  const { modal, setModal } = useModal()
  const userId = modal?.userId
  const { users, setUsers, userLists } = useUsers()
  const [user, setUser] = React.useState(undefined)
  const [newUser, setNewUser] = React.useState(false)

  const onClose = () => {
    setModal(undefined)
    setUser(undefined)
  }

  const handleChange = update => {
    setUser(prev => ({ ...prev, ...update }))
  }

  const { token, logout } = useUser()
  const toast = useToast()
  const submitUser = () => {
    apiFetch(`/api/users/${newUser ? '' : user.id}`, token, logout, {
      // logData: true,
      // logPerf: true,
      method: newUser ? 'POST' : 'PUT',
      data: user,
    })
      .then(response => {
        if (response?.error) {
          throw new Error(response?.message)
        }
        console.log('New entry', response)
        toast({
          title: `${response.name_for_status} ${newUser ? 'added' : 'updated'}`,
          status: 'success',
          duration: 1500,
          isClosable: true,
          position: 'bottom-right',
        })
        setUsers(prev =>
          sortBy(
            [...prev.filter(({ id }) => id !== response.id), response],
            [u => u.name_for_status.toLowerCase()]
          )
        )
        onClose()
      })
      .catch(error => {
        const { message: description } = error
        toast({
          title: `Error ${newUser ? 'Adding' : 'Updating'} User ${
            user.name_for_status
          }`,
          description,
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        })
        console.error(error)
      })
  }

  React.useEffect(
    function findUser() {
      if (userId === 'new') {
        setNewUser(true)
        setUser({
          email: '',
          active: true,
          name_for_status: '',
          show_in_aa: false,
          show_in_coding: false,
          show_in_dp: false,
          show_in_field: false,
          show_in_moderation: false,
          show_in_programming: false,
          show_in_research: false,
        })
      }
      const userToEdit = users.find(user => user.id === userId)
      if (userToEdit) {
        setUser(userToEdit)
      }
    },
    [userId, users]
  )

  return (
    <Modal isOpen={user !== undefined} onClose={onClose} size={'lg'}>
      <ModalOverlay backdropFilter={'blur(5px) saturate(100%)'} />
      <ModalContent background={useColorModeValue('white', 'blackAlpha.900')}>
        <ModalHeader>
          {newUser ? 'New User' : `Edit User: ${user?.name_for_status}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack direction={'row'} spacing={14} justify={'space-around'}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel htmlFor={'name_for_status'}>Name</FormLabel>
                <Input
                  borderColor={useColorModeValue('gray.100', 'gray.600')}
                  _focus={{
                    bg: useColorModeValue('gray.100', 'gray.800'),
                  }}
                  color={useColorModeValue('gray.800', 'gray.200')}
                  id={'name_for_status'}
                  value={user?.name_for_status ?? ''}
                  onChange={e =>
                    handleChange({ name_for_status: e.target.value })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={'email'}>Email</FormLabel>
                <Input
                  color={useColorModeValue('gray.800', 'gray.200')}
                  _focus={{
                    bg: useColorModeValue('gray.100', 'gray.800'),
                  }}
                  borderColor={useColorModeValue('gray.100', 'gray.600')}
                  id={'email'}
                  type={'email'}
                  value={user?.email ?? ''}
                  onChange={e => handleChange({ email: e.target.value })}
                />
              </FormControl>

              <FormControl display={'flex'} alignItems={'center'}>
                <FormLabel htmlFor="active" mb="0">
                  Active
                </FormLabel>
                <Switch
                  onChange={e => handleChange({ active: e.target.checked })}
                  id="active"
                  isChecked={user?.active}
                  colorScheme={'purple'}
                />
              </FormControl>
            </Stack>
            <Stack spacing={4}>
              <FormControl
                as={'fieldset'}
                sx={{ '& .chakra-checkbox__control': { color: 'gray.200' } }}
              >
                <FormLabel as={'legend'}>Departments</FormLabel>
                <Grid autoColumns>
                  {Object.keys(userLists).map(department => (
                    <Checkbox
                      id={department}
                      name={department}
                      key={department}
                      isChecked={user?.[`show_in_${department}`]}
                      colorScheme={'purple'}
                      onChange={e =>
                        handleChange({
                          [`show_in_${department}`]: e.target.checked,
                        })
                      }
                    >
                      {department.length !== 2
                        ? titleCase(department)
                        : department.toUpperCase()}
                    </Checkbox>
                  ))}
                </Grid>
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Stack direction={'row'} spacing={3}>
            <Button onClick={onClose} variant={'ghost'}>
              Cancel
            </Button>
            <Button float={'right'} colorScheme={'green'} onClick={submitUser}>
              Submit
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
