import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import {
  Box,
  Stack,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import { merge } from 'lodash'
import React from 'react'

export function ToggleGroupItem({ children, tooltip, tooltipOpen, ...props }) {
  // const groupName = groups[props.value].split(' ')[1] ?? groups[props.value]

  const { colorScheme, size, direction } = React.useContext(StyleContext)
  const { colorMode } = useColorMode()
  const defaultColor = useColorModeValue('gray.800', 'whiteAlpha.800')
  const {
    _hover: { bg },
    color = defaultColor,
    h,
    fontSize,
  } = getItemStyle(colorScheme, colorMode, size, direction)

  return (
    <Tooltip
      isOpen={tooltip && tooltipOpen}
      label={tooltip}
      bg={bg}
      color={color}
      h={h}
      fontSize={fontSize}
      placement={direction === 'row' ? 'bottom' : 'right'}
      offset={[0, 0]}
      fontWeight={'semibold'}
      display={'flex'}
      justify={'center'}
      alignItems={'center'}
      borderLeftRadius={0}
      lineHeight={'1'}
      portalProps={{
        className: 'toggle-group-tooltip',
      }}
      {...props}
      // boxShadow={'0 0 0 2px black'}
    >
      <Box
        zIndex={'10'}
        className="toggle-group-item"
        as={ToggleGroupPrimitive.Item}
        {...props}
        sx={{ zIndex: 10 }}
      >
        {children}
      </Box>
    </Tooltip>
  )
}

/**
 * [From Chakra-UI Button](https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/button.ts)
 */

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
}
const sizes = {
  lg: {
    h: 6,
    w: 6,
    fontSize: 'md',
    px: 3,
  },
  md: {
    h: 5,
    w: 5,
    fontSize: 'sm',
    px: 2,
  },
  sm: {
    h: 4,
    w: 4,
    fontSize: 'xs',
    px: 1.5,
  },
  xs: {
    h: 3,
    w: 3,
    fontSize: '0.625rem',
    px: 1,
  },
}
const getItemStyle = (colorScheme, colorMode, size, direction) => {
  const isRow = direction === 'row'
  const baseStyle = {
    all: 'unset',
    ...sizes[size],
    // height: '35px',
    // width: '35px',
    ...(isRow ? { marginLeft: 'px' } : { marginTop: 'px' }),
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    _hover: {
      _disabled: {
        bg: 'initial',
      },
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
      boxShadow: 'none',
    },
    _focus: {
      position: 'relative',
      zIndex: 1,
      shadow: `0 0 0 ${['xs', 'md'].includes(size) ? '2px' : '2px'} ${
        colorMode === 'light' ? 'black' : `whitesmoke`
      }`,
    },
    '&:first-of-type': {
      marginLeft: 0,
      borderTopLeftRadius: 4,
      ...(isRow
        ? { marginLeft: 0, borderBottomLeftRadius: 4 }
        : {
            marginTop: 0,
            borderTopRightRadius: 4,
            _hover: { borderTopRightRadius: 0 },
            _focus: { borderTopRightRadius: 0 },
            "&[data-state='on']": { borderTopRightRadius: 0 },
          }),
    },
    '&:last-of-type': {
      borderBottomRightRadius: 4,
      ...(isRow
        ? { borderTopRightRadius: 4 }
        : {
            borderBottomLeftRadius: 4,
            _hover: { borderBottomRightRadius: 0 },
            _focus: { borderBottomRightRadius: 0 },
            "&[data-state='on']": { borderBottomRightRadius: 0 },
          }),
      // borderTopRightRadius: 4,
    },
  }
  if (colorScheme === 'gray') {
    const bg = colorMode === 'light' ? 'gray.100' : 'whiteAlpha.200'
    const specificStyle = {
      bg,
      _hover: {
        bg: colorMode === 'light' ? 'gray.200' : 'whiteAlpha.300',
        _disabled: {
          bg,
        },
      },
      '&[data-state=on]': {
        bg: colorMode === 'light' ? 'gray.300' : 'whiteAlpha.400',
      },
    }
    return merge(baseStyle, specificStyle)
  }
  const {
    bg = `${colorScheme}.400`,
    color = 'white',
    hoverBg = `${colorScheme}.600`,
    activeBg = `${colorScheme}.700`,
  } = accessibleColorMap[colorScheme] ?? {}

  const background = colorMode === 'light' ? bg : `${colorScheme}.200`

  const specificStyle = {
    bg: background,
    color: colorMode === 'light' ? color : `gray.800`,
    _hover: {
      bg: colorMode === 'light' ? hoverBg : `${colorScheme}.300`,
      _disabled: {
        bg: background,
      },
    },
    '&[data-state=on]': {
      bg: colorMode === 'light' ? activeBg : `${colorScheme}.400`,
    },
  }
  return merge(baseStyle, specificStyle)
}

const StyleContext = React.createContext({ colorScheme: 'gray', size: 'md' })

export function ToggleGroup({
  type,
  colorScheme = 'gray',
  size = 'md',
  direction = 'row',
  children,
  ...props
}) {
  const { colorMode } = useColorMode()
  const itemStyle = getItemStyle(colorScheme, colorMode, size, direction)
  return (
    <Stack
      as={ToggleGroupPrimitive.Root}
      display={'inline-flex'}
      direction={direction}
      orientation={direction === 'row' ? 'horizontal' : 'vertical'}
      {...props}
      borderRadius={'md'}
      fontSize={'15px'}
      fontWeight={'semibold'}
      type={type}
      sx={{
        '& .toggle-group-item': { ...itemStyle },
      }}
      {...props}
    >
      <StyleContext.Provider value={{ colorScheme, size, direction }}>
        {children}
      </StyleContext.Provider>
    </Stack>
  )
}
