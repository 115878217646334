import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './theme'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'
import { Routes, Route, Outlet } from 'react-router-dom'
import MagicLinkForm from './auth/components/MagicLinkForm'
import PublicRoute from './components/PublicRoute'
import PrivateRoute from './components/PrivateRoute'
import ApiProvider from './contexts/ApiProvider'
import UserProvider from './contexts/UserProvider'
import ShowArchiveProvider from './contexts/ShowArchiveProvider'
import LoginPage from './pages/LoginPage'
import NewSheet from './NewSheet/NewSheet'
import Users from './users/Users'
import SalesforceJobs from './salesforcejobs/SalesforceJobs'
import UserJobs from './NewSheet/UserJobs'
import AAHub from './aa/AAHub.js'
import RequireRole from './components/RequireRole.js'
import Layout from './components/Layout.js'
import SearchProvider from './contexts/SearchProvider.js'
import RllModalProvider from './contexts/RllModalProvider.js'
import ModalProvider from './contexts/ModalProvider.js'
import UsersProvider from './contexts/UsersProvider.js'
import AAProjects from './aa/AAProjects.js'

export const PollContext = React.createContext()

function App() {
  const [message, setMessage] = React.useState('')

  const [currentPolls, setCurrentPolls] = React.useState([])
  const pollData = React.useMemo(
    () => ({
      currentPolls,
      addPoll: pollingUrl =>
        setCurrentPolls(prev =>
          prev.includes(pollingUrl) ? prev : [...prev, pollingUrl]
        ),
      removePoll: pollingUrl =>
        setCurrentPolls(prev => prev.filter(url => url !== pollingUrl)),
    }),
    [currentPolls]
  )
  return (
    <ChakraProvider theme={theme}>
      <ApiProvider>
        <UserProvider>
          <PollContext.Provider value={pollData}>
            <Routes>
              <Route
                path="/login"
                element={
                  <PublicRoute>
                    <MagicLinkForm message={message} setMessage={setMessage} />
                  </PublicRoute>
                }
              />{' '}
              <Route
                path="/verify"
                element={
                  <PublicRoute>
                    <LoginPage />
                  </PublicRoute>
                }
              />
              <Route
                path={'*'}
                element={
                  <PrivateRoute>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <ModalProvider>
                            <RllModalProvider>
                              <UsersProvider>
                                <ShowArchiveProvider>
                                  <SearchProvider>
                                    <Layout>
                                      <Outlet />
                                    </Layout>
                                  </SearchProvider>
                                </ShowArchiveProvider>
                              </UsersProvider>
                            </RllModalProvider>
                          </ModalProvider>
                        }
                      >
                        <Route path={'status'} element={<NewSheet />} />
                        <Route
                          path={'users'}
                          element={
                            <RequireRole role={100}>
                              <Users />
                            </RequireRole>
                          }
                        />
                        <Route path={'user/'} element={<UserJobs />} />
                        <Route path={'user/jobs'} element={<UserJobs />} />
                        <Route path={'user/:id/jobs'} element={<UserJobs />} />
                        <Route
                          path={'salesforcejobs'}
                          element={
                            <RequireRole role={100}>
                              <SalesforceJobs />
                            </RequireRole>
                          }
                        />
                        <Route path={'department/aa'} element={<AAHub />} />
                        <Route
                          path={'department/aa/projects'}
                          element={<AAProjects />}
                        />
                        <Route path={'/'} element={<NewSheet />} />
                      </Route>
                    </Routes>
                  </PrivateRoute>
                }
              />
            </Routes>
          </PollContext.Provider>
        </UserProvider>
      </ApiProvider>
    </ChakraProvider>
  )
}

export default App
