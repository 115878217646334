import React, { useMemo } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { apiFetch } from '../auth/apiFetch'
import { useUser } from '../contexts/UserProvider'
import { WiCloudRefresh } from 'react-icons/all'
import {
  ArrowDownIcon,
  CheckIcon,
  CopyIcon,
  RepeatClockIcon,
} from '@chakra-ui/icons'
import { useModal } from '../contexts/ModalProvider'
import JobModal from '../components/JobModal'
import { useSearchParams } from 'react-router-dom'

export default function SalesforceJobs() {
  const [jobs, setJobs] = React.useState(undefined)
  const [requestRefresh, setRequestRefresh] = React.useState(false)
  const [requestApiOnlyRefresh, setRequestApiOnlyRefresh] =
    React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const { logout, token } = useUser()
  const { modal, setModal } = useModal()
  // const { showArchive } = React.useContext(ShowArchiveContext)
  const [searchParams] = useSearchParams()
  const showArchive = searchParams.get('archived') === 'true'
  let archived = useMemo(
    () =>
      searchParams.get('archived') !== null
        ? searchParams.get('archived').split(',')
        : ['false'],
    [searchParams]
  )
  let dataCollection = useMemo(
    () =>
      searchParams.get('dataCollection') !== null
        ? searchParams.get('dataCollection').split(',')
        : ['qual', 'quant', 'unknown'],
    [searchParams]
  )
  let pending = useMemo(
    () =>
      searchParams.get('pending') !== null
        ? searchParams.get('pending').split(',')
        : ['false'],
    [searchParams]
  )
  const handleSetArchived = (id, archived) => {
    apiFetch('/api/salesforcejobs/' + id, token, logout, {
      method: 'PUT',
      data: {
        archived,
      },
    })
      .then(newJob => {
        setJobs(prev => [...prev.filter(job => job.id !== id), newJob])
        setRequestApiOnlyRefresh(true)
      })
      .catch(e => {
        console.log(e)
        alert(e?.message)
      })
  }
  React.useEffect(
    function getSalesforceJobs() {
      if (
        jobs !== undefined &&
        requestRefresh === false &&
        requestApiOnlyRefresh === false
      )
        return
      const queryString = new URLSearchParams({
        // page,
        // per_page: perPage,
        archived,
        pending,
        dataCollection,
        refresh: requestRefresh ? 1 : 0,
      }).toString()
      const endpoint = `/api/salesforcejobs?${queryString}`
      setRequestRefresh(false)
      setRequestApiOnlyRefresh(false)
      setLoading(true)
      apiFetch(endpoint, token, logout)
        .then(jobData => setJobs(jobData))
        .finally(() => setLoading())
    },
    [
      jobs,
      token,
      logout,
      requestRefresh,
      modal,
      archived,
      pending,
      dataCollection,
      searchParams,
      requestApiOnlyRefresh,
    ]
  )
  React.useEffect(
    function apiRefreshAfterModalOpen() {
      if (jobs === undefined) return
      setRequestApiOnlyRefresh(true)
    },
    [modal]
  )
  const subtleGray = useColorModeValue('gray.200', 'whiteAlpha.300')
  const archiveColor = useColorModeValue('red.500', 'red.300')
  const addedColor = useColorModeValue('gray.500', 'whiteAlpha.500')
  return jobs ? (
    jobs?.length > 0 ? (
      <Box w={'full'}>
        <JobModal />
        <Button
          onClick={() => setRequestRefresh(true)}
          colorScheme={'purple'}
          m={2}
          size="sm"
          // variant={'ghost'}
          float={'right'}
          leftIcon={<WiCloudRefresh fontSize={'1.6rem'} />}
          isLoading={loading}
          loadingText={'Refreshing...'}
          position={'fixed'}
          top={'4px'}
          right={'15px'}
        >
          Get Newest Salesforce Data
        </Button>
        <Table w={'full'}>
          <Thead>
            <Th />
            <Th>Open Date</Th>
            <Th>Close Date</Th>
            <Th>Job #</Th>
            <Th>Client</Th>
            <Th>Description</Th>
            <Th>Stage</Th>
            <Th>Added to Status Sheet</Th>
          </Thead>
          <Tbody>
            {jobs
              // .filter(({ archived }) => archived === showArchive)
              .map(job => (
                <Tr
                  key={job.jobNum}
                  sx={
                    job?.numJobs > 0 ? { '& td': { color: addedColor } } : null
                  }
                >
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        size={'xs'}
                        aria-label={'Job Menu'}
                        icon={<ArrowDownIcon />}
                        fontSize={'1.5em'}
                      >
                        ↓
                      </MenuButton>
                      <MenuList
                        zIndex={'9998'}
                        _css={{
                          zIndex: '9998',
                        }}
                      >
                        <MenuItem
                          icon={<CopyIcon />}
                          onClick={() => setModal({ sfJob: job })}
                        >
                          Add to Status Sheet
                        </MenuItem>
                        <MenuDivider color={subtleGray} />
                        <MenuItem
                          icon={<RepeatClockIcon />}
                          onClick={() =>
                            handleSetArchived(job.id, !showArchive)
                          }
                          // onClick={() => handleUpdate({ archived: !showArchive })}
                          color={showArchive ? null : archiveColor}
                        >
                          {showArchive ? 'Make Active' : 'Archive'}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                  <Td>
                    {job?.openDate
                      ? job.openDate.split('/').slice(0, 3).join('/')
                      : ''}
                  </Td>
                  <Td>
                    {job?.closeDate
                      ? job.closeDate.split('/').slice(0, 3).join('/')
                      : ''}
                  </Td>
                  <Td>{job.jobNum}</Td>
                  <Td>{job.client}</Td>
                  <Td>{job.description}</Td>
                  <Td>{job.stage}</Td>
                  <Td>{job.numJobs > 0 ? <CheckIcon /> : ''}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    ) : (
      <Flex w={'full'} h={'full'} p={20} justify={'center'}>
        <Heading textAlign={'center'}>No new jobs from Salesforce.</Heading>
      </Flex>
    )
  ) : (
    <Flex w={'full'} h={'full'} p={20} justify={'center'}>
      <Spinner
        thickness="4px"
        speed="1s"
        emptyColor="gray.200"
        color="purple.500"
        size="xl"
        sx={{ zoom: '5' }}
        opacity={0.5}
      />
    </Flex>
  )
}
