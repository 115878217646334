import React, { useMemo } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import { useUser } from '../contexts/UserProvider'
import { apiFetch } from '../auth/apiFetch'
import { Flex, Heading, Spinner, Stack } from '@chakra-ui/react'
import Job from './Job2'
import JobModal from '../components/JobModal'
import Pagination from './Pagination'
import JobSearch from '../components/JobSearch'
import RllModal from '../components/RllModal'
import { useRllModal } from '../contexts/RllModalProvider'

export default function UserJobs() {
  const { id } = useParams('id')
  const [jobs, setJobs] = React.useState(undefined)
  const [error, setError] = React.useState(undefined)
  const { token, logout } = useUser()
  const [searchParams, setSearchParams] = useSearchParams()
  // const archived = searchParams.get('showArchived')
  // const pending = searchParams.get('pending')
  let pending = useMemo(
    () =>
      searchParams.get('pending') !== null
        ? searchParams.get('pending').split(',')
        : ['false'],
    [searchParams]
  )
  let archived = useMemo(
    () =>
      searchParams.get('archived') !== null
        ? searchParams.get('archived').split(',')
        : ['false'],
    [searchParams]
  )
  let on_track = useMemo(
    () =>
      searchParams.get('on_track') !== null
        ? searchParams.get('on_track').split(',')
        : ['false', 'true'],
    [searchParams]
  )
  let dataCollection = useMemo(
    () =>
      searchParams.get('dataCollection') !== null
        ? searchParams.get('dataCollection').split(',')
        : ['qual', 'quant', 'unknown'],
    [searchParams]
  )
  let group = searchParams.get('group') || null
  let page = searchParams.get('page') || 1
  let perPage = searchParams.get('per_page') || 5
  let search = searchParams.get('search') || ''

  const [pagination, setPagination] = React.useState(undefined)

  React.useEffect(
    function getUserJobs() {
      const query = new URLSearchParams({
        page,
        per_page: perPage,
        archived,
        pending,
        on_track,
        dataCollection,
        group,
        ...(search.length > 0
          ? {
              search,
            }
          : {}),
      }).toString()
      apiFetch(`/api/jobs/user/${id ?? ''}?${query}`, token, logout)
        .then(response => {
          // setFetched(true)
          if (response?.error) {
            throw new Error(response?.message)
          }
          setError(undefined)
          // setJobs(getLatestVersions(response))
          setJobs(response.items)
          const { next, prev } = response._links

          let firstPage = prev ? new URLSearchParams(prev.split('?')[1]) : null
          firstPage?.set('page', 1)
          let lastPage =
            response._meta.total_pages !== response._meta.page && next
              ? new URLSearchParams(next.split('?')[1])
              : null
          lastPage?.set('page', response._meta.total_pages)

          setPagination({
            page: response._meta.page,
            perPage: response._meta.per_page,
            totalItems: response._meta.total_items,
            next: next ? new URLSearchParams(next.split('?')[1]) : null,
            prev: prev ? new URLSearchParams(prev.split('?')[1]) : null,
            firstPage,
            lastPage,
          })
        })
        .catch(error => setError(error?.message || 'Unknown error'))

      // return () => {
      //   setJobs(undefined)
      //   setError(undefined)
      //   setFetched(false)
      // }
    },
    [
      archived,
      dataCollection,
      group,
      id,
      logout,
      on_track,
      page,
      pending,
      perPage,
      search,
      token,
    ]
  )
  const handleSearch = search => {
    searchParams.set('page', 1)
    if (!search) {
      console.log('NO SEARCH', searchParams)
      searchParams.delete('search')
    } else {
      searchParams.set('search', search)
    }
    setSearchParams(searchParams)
  }
  // let showArchiveColor = useColorModeValue('gray.500', 'whiteAlpha.500')
  console.log({ pagination })
  return jobs ? (
    <>
      <Pagination {...pagination} numItemsOnCurrentPage={jobs.length} mr={2}>
        <JobSearch handleSubmit={handleSearch} />
      </Pagination>
      {jobs?.length > 0 ? (
        <>
          <Stack spacing={0}>
            <JobModal />
            <RllModal />
            {jobs.map((job, i) => (
              <Job
                job={job}
                key={job.id}
                useAbsolute={false}
                isScrolling={false}
                width={'full'}
              />
              // <JobForm
              //   key={job.jobNum}
              //   job={job}
              //   onChange={change => console.log(change)}
              //   useAbsolute={false}
              // />
            ))}
          </Stack>
        </>
      ) : (
        <Flex w={'full'} h={'full'} p={20} justify={'center'}>
          <Heading textAlign={'center'}>No jobs yet.</Heading>
        </Flex>
      )}
    </>
  ) : (
    <Flex w={'full'} h={'full'} p={20} justify={'center'}>
      <Spinner
        thickness="4px"
        speed="1s"
        emptyColor="gray.200"
        color="purple.500"
        size="xl"
        sx={{ zoom: '5' }}
        opacity={0.5}
      />
    </Flex>
  )
}
