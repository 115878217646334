// import React from 'react'
//
// import { usePageVisibility } from './components/utils/useVisibility'
// import { differenceBy } from 'lodash'
// import { PollContext } from '../App'

export async function apiFetch(
  endpoint,
  token,
  logout,
  { logData = false, logPerf = false, method = 'GET', blob = false, data } = {}
) {
  let t = performance.now()
  if (!endpoint || !token || !logout) {
    return
  }
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method,
  }
  if (['POST', 'PUT'].includes(method)) {
    if (data) {
      options.body = JSON.stringify(data)
      options.headers['Content-Type'] = 'application/json'
      options.headers['Access-Control-Expose-Headers'] = 'Location'
    }
  }
  let status = 0
  let statusText = ''
  let _headersReceived = undefined
  return await fetch(endpoint, options)
    .then(res => {
      status = res.status
      if (status === 204) return

      statusText = res.statusText
      _headersReceived = Object.fromEntries(res.headers.entries())

      if (blob) {
        return res.blob()
      }
      return res.json()
    })
    .then(data => {
      if (logData) {
        console.log(data)
      }
      switch (status) {
        case 200:
        case 201:
        case 400:
          if (['POST', 'PUT'].includes(method)) {
            data = { ...data, _headersReceived }
          }
          return data
        case 204:
          return
        case 401:
          logout()
          throw new Error(data.message)
        default:
          if (data.message) {
            throw new Error(data.message)
          }
          console.error(data)
          throw new Error(
            `${status}: Unexpected Error ${
              statusText ? ' - ' + statusText : ''
            }`
          )
      }
    })
    .catch(err => {
      throw new Error(
        `${status}: Unexpected Error ${statusText ? ' - ' + statusText : ''}`,
        { cause: err }
      )
    })
    .finally(() => {
      if (logPerf) {
        console.info(`${endpoint}: ${(performance.now() - t).toFixed(0)}ms`)
      }
    })
}
/**
 * Takes two lists of objects and returns a new list,
 * with all the objects in the first
 * plus all the objects in the second that are not in the first
 * ( [{id: 1}, {id: 2}], [{id: 3}, {id: 2}] ) => [{id: 1}, {id: 2}, {id: 3}]
 **/
// function appendNewEntries(prevData, newData) {
//   console.log({ newData, prevData })
//   if (!newData || !newData.length) {
//     return prevData
//   }
//   const objectsToAdd = differenceBy(newData, prevData, 'id')
//   if (newData.length) {
//     return [...prevData, ...objectsToAdd]
//   }
//   return prevData
// }

/**
 * Syncs data between state, the server, and optionally the IndexedDB
 * If an idbKey is provided, state will first be set to the data in the IndexedDB
 * then, data will be retrieved from the server, and any objects that were in the
 * server but not in the IndexedDb will be added to both state and IndexedDB
 * Data will be synced with API data every refreshRate seconds
 */
// export function useFreshApiSync(
//   endpoint,
//   token,
//   logout,
//   idbKey,
//   {
//     updateSuccessCallback = () => {},
//     refreshRate = undefined,
//     initialState = [],
//     onlyCheckWhenVisible = true,
//     fetchOpts = {},
//   } = {}
// ) {
//   const [loading, setLoading] = React.useState('Init')
//   const { data, setData } = useFreshApiData(endpoint, token, logout, {
//     refreshRate,
//     onlyCheckWhenVisible,
//     initialState,
//     fetchOpts,
//     newDataCallback: (newData, prevData) => appendNewEntries(prevData, newData),
//   })
//
//   const setterWithIdb = getIdbWrappedSetter(idbKey, setData)
//
//   // If an idbKey is provided, sets both the state and IndexedDB
//   // Otherwise, just sets state
//
//   // Sends data to server with update information for an entry
//   // If update is valid, receives updated entry from server
//   // state and IndexedDb are updated with new entry
//   const updateEntry = React.useCallback(
//     update =>
//       apiFetch(endpoint, token, logout, { method: 'PUT', data: update }).then(
//         response => {
//           if (response?.error) {
//             console.log(response.error)
//           } else {
//             console.log('Updated entry', response)
//             updateSuccessCallback(response)
//             setterWithIdb(prevData => [...prevData, response])
//           }
//         }
//       ),
//     [idbKey]
//   )
//
//   React.useEffect(
//     function initData() {
//       if (loading !== 'Init') {
//         return
//       }
//
//       const apiGetAndAppendNewData = () =>
//         apiFetch(endpoint, token, logout, { logData: true, logPerf: true })
//           .then(apiData => {
//             if (apiData) {
//               setterWithIdb(prevData => appendNewEntries(prevData, apiData))
//             }
//           })
//           .catch(err => console.error(err))
//           .finally(() => setLoading('Complete'))
//       if (loading === 'Init') {
//         setLoading('IDB')
//         if (idbKey) {
//           get(idbKey)
//             .then(idbData => {
//               if (idbData !== undefined) {
//                 setData(idbData)
//               }
//               setLoading('API')
//               apiGetAndAppendNewData()
//             })
//             .catch(error => {
//               if (error?.name === 'DataError') {
//                 setLoading('API')
//                 apiGetAndAppendNewData()
//               } else {
//                 console.error(error)
//               }
//             })
//         } else {
//           setLoading('API')
//           apiGetAndAppendNewData()
//         }
//       }
//     },
//     [loading]
//   )
//
//   return {
//     data,
//     updateEntry,
//   }
// }

/**
 * Syncs data between state an the API every refreshRate seconds
 */
// export function useFreshApiData(
//   endpoint,
//   token,
//   logout,
//   {
//     onlyCheckWhenVisible = true,
//     refreshRate = undefined,
//     fetchOpts = {},
//     newDataCallback = (newData, prevData = []) => newData,
//     initialState,
//   } = {}
// ) {
//   const [data, setData] = React.useState(initialState)
//   const isVisible = usePageVisibility()
//   const blockCheck = onlyCheckWhenVisible && !isVisible
//   const { addPoll, removePoll } = React.useContext(PollContext)
//   const getFreshData = React.useCallback(
//     () => apiFetch(endpoint, token, logout, fetchOpts),
//     [endpoint, logout, token]
//   )
//   const requestAPIUpdate = () => getFreshData().then(data => setData(data))
//
//   React.useEffect(
//     function createPoller() {
//       getFreshData().then(freshData =>
//         setData(prevData => newDataCallback(freshData, prevData))
//       )
//       if (!refreshRate) {
//         return
//       }
//
//       let poller = window.setInterval(() => {
//         if (blockCheck) {
//           return
//         }
//         addPoll(endpoint)
//         getFreshData()
//           .then(freshData => setData(freshData))
//           .finally(() =>
//             setTimeout(() => removePoll(endpoint), Math.round(refreshRate / 2))
//           )
//       }, refreshRate)
//
//       return () => window.clearInterval(poller)
//     },
//     [
//       addPoll,
//       blockCheck,
//       endpoint,
//       getFreshData,
//       logout,
//       removePoll,
//       refreshRate,
//       token,
//     ]
//   )
//
//   return { data, requestAPIUpdate, setData }
// }
