import { createContext, useState, useMemo, useContext } from 'react'

export const ShowArchiveContext = createContext()

export default function ShowArchiveProvider({ children }) {
  const [showArchive, setShowArchive] = useState(false)
  const showArchiveData = useMemo(
    () => ({
      showArchive,
      setShowArchive,
    }),
    [showArchive]
  )
  return (
    <ShowArchiveContext.Provider value={showArchiveData}>
      {children}
    </ShowArchiveContext.Provider>
  )
}

export function useShowArchive() {
  const context = useContext(ShowArchiveContext)
  return context
}
