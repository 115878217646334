import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Select,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  // Link as RouterLink,
  useLocation,
  useSearchParams,
} from 'react-router-dom'

import React, { useCallback, useMemo } from 'react'
import { AddIcon, ViewIcon } from '@chakra-ui/icons'
import { titleCase } from '../utils/strings'
import { FaFilter } from 'react-icons/fa'
import { GrFilter } from 'react-icons/gr'

import { useModal } from '../contexts/ModalProvider'
import { useUsers } from '../contexts/UsersProvider'

export const VIEWS = {
  default: {
    archived: 'false',
    pending: 'false',
    on_track: 'false,true',
    dataCollection: 'qual,quant,unknown',
  },
  archived: {
    archived: 'true',
    pending: 'false',
    on_track: 'false,true',
    dataCollection: 'qual,quant,unknown',
  },
  pending: {
    archived: 'false',
    pending: 'true',
    on_track: 'false,true',
    dataCollection: 'qual,quant,unknown',
  },
  pendingQual: {
    archived: 'false',
    pending: 'true',
    on_track: 'false,true',
    dataCollection: 'qual',
  },
  pendingQuant: {
    archived: 'false',
    pending: 'true',
    on_track: 'false,true',
    dataCollection: 'quant',
  },
  offTrack: {
    archived: 'false',
    pending: 'false,true',
    on_track: 'false',
    dataCollection: 'qual,quant,unknown',
  },
}

const hideScrollbar = {
  '& ::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
    backgroundColor: 'transparent',
    display: 'none',
    // backgroundColor: 'red',
  },
  '& ::-webkit-scrollbar-thumb': {
    borderRadius: '12px',
  },
  '& ::-webkit-scrollbar-track': {
    padding: '2px',
    backgroundColor: 'transparent',
  },
}

export default function Content({ children }) {
  // const { setShowArchive, showArchive } = React.useContext(ShowArchiveContext)
  const borderColor = useColorModeValue('gray.100', 'gray.700')
  const location = useLocation()
  const { pathname } = location
  const path = pathname.split('/').slice(-1)[0]
  const [searchParams, setSearchParams] = useSearchParams()
  let showArchive = useMemo(
    () =>
      searchParams.get('archived') !== null
        ? searchParams.get('archived').split(',')
        : ['false'],
    [searchParams]
  )
  const showPending =
    searchParams.get('pending') !== null
      ? searchParams.get('pending').split(',')
      : ['true']
  const showOnTrack =
    searchParams.get('on_track') !== null
      ? searchParams.get('on_track').split(',')
      : ['false', 'true']
  const showDataCollection =
    searchParams.get('dataCollection') !== null
      ? searchParams.get('dataCollection').split(',')
      : ['qual', 'quant', 'unknown']
  // const pageNum = parseInt(searchParams.get('page') ?? 0, 10)
  const hideScroll = ['/timeline'].some(p => pathname.includes(p))
  const showSheetControls = !['/timeline', 'user', 'salesforcejobs'].some(p =>
    pathname.toLowerCase().includes(p)
  )

  const showUserControls = pathname.includes('/users')
  const scrollbarStyle = hideScroll ? hideScrollbar : {}

  const { userLists, setUserFilter, userFilter } = useUsers()
  const { setModal } = useModal()
  const showFilterMenus = ['jobs', 'status', 'salesforcejobs'].some(
    p => path.toLowerCase() === p
  )
  const isSalesforcejobs = path.toLowerCase() === 'salesforcejobs'
  const isUserJobs = path.toLowerCase() === 'jobs'

  // const { user } = React.useContext(AppContext)
  // const showArchiveModeSwitch =
  //   showSheetControls || pathname.includes('/salesforcejobs')
  // const isAdmin = user?.role && user.role.some(role => role >= 100)
  // const isField =
  //   user?.departments &&
  //   user.departments.some(department => department === 'field')

  // const showPendingSwitch = isAdmin || isField
  const handleToggleArchive = value => {
    const page = searchParams.get('page') ?? '1'
    if (page !== '1') {
      searchParams.set('page', '1')
    }
    searchParams.set(
      'archived',
      value.length > 0
        ? value
            .map(v => v.toLowerCase() === 'true')
            .sort()
            .join(',')
        : ''
    )
    setSearchParams(searchParams)
  }
  // const handleTogglePending = e =>
  //   setSearchParams({
  //     ...Object.fromEntries(searchParams),
  //     pending: e.target.checked,
  //   })
  const handleTogglePending = value => {
    const page = searchParams.get('page') ?? '1'
    if (page !== '1') {
      searchParams.set('page', '1')
    }
    searchParams.set(
      'pending',
      value.length > 0
        ? value
            .map(v => v.toLowerCase() === 'true')
            .sort()
            .join(',')
        : ''
    )
    setSearchParams(searchParams)
  }
  const handleToggleOnTrack = value => {
    const page = searchParams.get('page') ?? '1'
    if (page !== '1') {
      searchParams.set('page', '1')
    }
    searchParams.set(
      'on_track',
      value.length > 0
        ? value
            .map(v => v.toLowerCase() === 'true')
            .sort()
            .join(',')
        : ''
    )
    setSearchParams(searchParams)
  }
  const handleToggleDataCollection = value => {
    const page = searchParams.get('page') ?? '1'
    if (page !== '1') {
      searchParams.set('page', '1')
    }
    searchParams.set(
      'dataCollection',
      value.length > 0
        ? value
            .map(v => v.toLowerCase())
            .sort()
            .join(',')
        : ''
    )
    setSearchParams(searchParams)
  }
  const activeView =
    Object.keys(VIEWS).find(viewName =>
      Object.entries(VIEWS[viewName]).every(
        ([key, value]) =>
          JSON.stringify(searchParams.get(key)).toLowerCase() ===
          JSON.stringify(value).toLowerCase()
      )
    ) ?? 'custom'

  const handleViewChange = useCallback(
    viewName => {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        page: '1',
        ...VIEWS[viewName],
      })
    },
    [searchParams, setSearchParams]
  )

  return (
    <Flex
      as={'main'}
      position={'relative'}
      overflow={'auto'}
      placeItems={'stretch'}
      direction={'column'}
      flexShrink={'initial'}
      flexBasis={'initial'}
      flexGrow={1}
      sx={scrollbarStyle}
    >
      <Box />
      <Flex
        id={'top-bar'}
        flexBasis={'initial'}
        direction={'column'}
        flexGrow={0}
        flexShrink={0}
      >
        <Flex
          alignItems={'center'}
          py={0}
          pl={6}
          pr={9}
          as={'header'}
          maxW={'100vw'}
          minH={57}
          borderBottom={'1px solid'}
          borderColor={borderColor}
        >
          <Box
            as={'span'}
            flexShrink={1}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            fontSize={'md'}
          >
            {/*{pathname.split('/')[2]}*/}
          </Box>
          <Flex
            id="top-bar-right-container"
            ml={2}
            flexShrink={'initial'}
            flexBasis={'initial'}
            direction={'row'}
            alignItems={'center'}
            flexGrow={1}
          >
            <Flex
              id="top-bar-filler"
              flexShrink={'initial'}
              flexBasis={'initial'}
              direction={'row'}
              flexGrow={1}
              justifyContent={'start'}
            >
              {showFilterMenus ? (
                <>
                  {!isSalesforcejobs ? (
                    <Menu closeOnSelect={false}>
                      <Tooltip label="Views">
                        <MenuButton
                          as={IconButton}
                          icon={<ViewIcon />}
                          variant="ghost"
                          colorScheme={
                            !['custom', 'default'].includes(activeView)
                              ? 'purple'
                              : undefined
                          }
                        />
                      </Tooltip>
                      <MenuList minWidth={'240px'} fontSize="sm">
                        <MenuOptionGroup
                          fontSize="lg"
                          title="Quick Views"
                          type="radio"
                          onChange={viewName => handleViewChange(viewName)}
                          value={activeView}
                        >
                          <MenuItemOption closeOnSelect value="default">
                            Default View{' '}
                            <Text color="gray.500" as="span">
                              (Open & Active)
                            </Text>
                          </MenuItemOption>
                          <MenuItemOption closeOnSelect value="archived">
                            Archived Jobs
                          </MenuItemOption>
                          <MenuItemOption closeOnSelect value="offTrack">
                            Off Track Jobs
                          </MenuItemOption>
                          <MenuItemOption
                            value={'custom'}
                            isDisabled
                            color="gray.700"
                          >
                            Custom View
                          </MenuItemOption>
                          <MenuDivider borderColor="gray.300" />
                          <MenuOptionGroup
                            title="Pending Views"
                            type="radio"
                            onChange={viewName => handleViewChange(viewName)}
                            value={activeView}
                          >
                            <MenuItemOption closeOnSelect value="pending">
                              All Pending Jobs
                            </MenuItemOption>
                            <MenuItemOption closeOnSelect value="pendingQual">
                              Pending Qual Jobs
                            </MenuItemOption>
                            <MenuItemOption closeOnSelect value="pendingQuant">
                              Pending Quant Jobs
                            </MenuItemOption>
                          </MenuOptionGroup>
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                  ) : null}
                  <Menu closeOnSelect={false}>
                    {/* Filter Menu */}
                    <Tooltip label="Filters">
                      <MenuButton
                        as={IconButton}
                        icon={
                          activeView === 'custom' ? <FaFilter /> : <GrFilter />
                        }
                        variant="ghost"
                        colorScheme={
                          activeView === 'custom' ? 'purple' : undefined
                        }
                      />
                    </Tooltip>
                    <MenuList minWidth={'240px'} fontSize="sm">
                      <MenuGroup
                        color="gray.600"
                        title="Filters (Advanced)"
                        fontSize="md"
                      >
                        <MenuOptionGroup
                          title="Archived"
                          type="checkbox"
                          onChange={handleToggleArchive}
                          value={showArchive}
                          fontSize="xs"
                          color="gray.600"
                        >
                          <MenuItemOption
                            fontSize="xs"
                            color="gray.600"
                            value="false"
                          >
                            Open
                          </MenuItemOption>
                          <MenuItemOption
                            fontSize="xs"
                            color="gray.600"
                            value="true"
                          >
                            Archived
                          </MenuItemOption>
                        </MenuOptionGroup>
                        {!isSalesforcejobs ? (
                          <MenuOptionGroup
                            title="On Track"
                            fontSize="xs"
                            color="gray.600"
                            type="checkbox"
                            onChange={handleToggleOnTrack}
                            value={showOnTrack}
                          >
                            <MenuItemOption
                              fontSize="xs"
                              color="gray.600"
                              value="true"
                            >
                              On Track
                            </MenuItemOption>
                            <MenuItemOption
                              fontSize="xs"
                              color="gray.600"
                              value="false"
                            >
                              Off Track
                            </MenuItemOption>
                          </MenuOptionGroup>
                        ) : null}
                        <MenuOptionGroup
                          title="Pending"
                          type="checkbox"
                          onChange={handleTogglePending}
                          value={showPending}
                          fontSize="xs"
                          color="gray.600"
                        >
                          <MenuItemOption
                            fontSize="xs"
                            color="gray.600"
                            value="false"
                          >
                            Active
                          </MenuItemOption>
                          <MenuItemOption
                            fontSize="xs"
                            color="gray.600"
                            value="true"
                          >
                            Pending
                          </MenuItemOption>
                        </MenuOptionGroup>
                        {!isUserJobs ? (
                          <MenuOptionGroup
                            title="Data Collection"
                            type="checkbox"
                            onChange={handleToggleDataCollection}
                            value={showDataCollection}
                            fontSize="xs"
                            color="gray.600"
                          >
                            <MenuItemOption
                              fontSize="xs"
                              color="gray.600"
                              value="qual"
                            >
                              Qualitative
                            </MenuItemOption>
                            <MenuItemOption
                              fontSize="xs"
                              color="gray.600"
                              value="quant"
                            >
                              Quantitative
                            </MenuItemOption>
                            <MenuItemOption
                              fontSize="xs"
                              color="gray.600"
                              value="unknown"
                            >
                              Unknown
                            </MenuItemOption>
                          </MenuOptionGroup>
                        ) : null}
                      </MenuGroup>
                    </MenuList>
                  </Menu>
                </>
              ) : null}
              {/* top bar stuff */}
              {/* {isAdmin && showArchiveModeSwitch ? (
                <FormControl
                  display="flex"
                  alignItems="center"
                  mr={6}
                  whiteSpace={'nowrap'}
                >
                  <FormLabel
                    htmlFor="show-archive"
                    mb="0"
                    display={'inline'}
                    fontSize={'sm'}
                    color={showArchiveColor}
                  >
                    Archived
                  </FormLabel>
                  <Switch
                    value={showArchive}
                    onChange={handleToggleArchive}
                    id="show-archive"
                  />
                </FormControl>
              ) : null} */}
              {/* {showPendingSwitch ? (
                <FormControl
                  display="flex"
                  alignItems="center"
                  mr={6}
                  whiteSpace={'nowrap'}
                >
                  <FormLabel
                    htmlFor="show-pending"
                    mb="0"
                    display={'inline'}
                    fontSize={'sm'}
                    color={showArchiveColor}
                  >
                    Pending
                  </FormLabel>
                  <Switch
                    value={showPending}
                    onChange={handleTogglePending}
                    id="show-pending"
                  />
                </FormControl>
              ) : null}
              {showPendingSwitch ? (
                <FormControl
                  display="flex"
                  alignItems="center"
                  mr={32}
                  whiteSpace={'nowrap'}
                >
                  <FormLabel
                    htmlFor="show-off_track"
                    mb="0"
                    display={'inline'}
                    fontSize={'sm'}
                    color={showArchiveColor}
                  >
                    Off Track
                  </FormLabel>
                  <Switch
                    value={showOffTrack}
                    onChange={handleToggleOffTrack}
                    id="show-off_track"
                  />
                </FormControl>
              ) : null} */}
            </Flex>
            <Flex
              id="top-bar-right-items"
              ml={4}
              flexGrow={'initial'}
              flexBasis={'initial'}
              direction={'row'}
              alignItems={'center'}
              justify={'flex-end'}
              flexShrink={0}
              fontSize={'sm'}
            >
              {showSheetControls
                ? [
                    // <JobSearch key={'search'} />,
                    // <Select
                    //   key={'page'}
                    //   placeholder={'Change page'}
                    //   value={pageNum}
                    //   minW={'160px'}
                    //   variant={'filled'}
                    //   onChange={e => {
                    //     navigate(`${pathname}?page=${e.target.value}`, {
                    //       state: { from: 'pageSelect' },
                    //     })
                    //   }}
                    // >
                    //   {pages.map((_, i) => (
                    //     <option key={i} value={i + 1}>
                    //       Page {i + 1}
                    //     </option>
                    //   ))}
                    // </Select>,
                  ]
                : showUserControls
                ? [
                    <Select
                      placeholder={'All Departments'}
                      variant={'filled'}
                      w={'180px'}
                      mr={4}
                      foat={'left'}
                      value={userFilter}
                      onChange={e => setUserFilter(e.target.value)}
                    >
                      {userLists &&
                        Object.keys(userLists).map(department => (
                          <option key={department} value={department}>
                            {department.length !== 2
                              ? titleCase(department)
                              : department.toUpperCase()}
                          </option>
                        ))}
                    </Select>,
                    <Button
                      onClick={() => setModal({ userId: 'new' })}
                      leftIcon={<AddIcon />}
                      key={'add-user'}
                      colorScheme={'purple'}
                    >
                      Add User
                    </Button>,
                  ]
                : null}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Box />
      <Flex
        id={'main-content'}
        flexGrow={1}
        alignItems={'stretch'}
        height={'100%'}
        overflow={hideScroll ? 'hidden' : 'auto'}
        position={'relative'}
      >
        {children}
      </Flex>
    </Flex>
  )
}
