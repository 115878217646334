import { useLocation } from 'react-router-dom'
import {
  Flex,
  Link,
  useColorModeValue,
  Text,
  IconButton,
  HStack,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@chakra-ui/icons'

export default function Pagination({
  page,
  perPage,
  numItemsOnCurrentPage,
  totalItems,
  next,
  prev,
  firstPage,
  lastPage,
  children,
}) {
  const startingItem = (page - 1) * perPage + 1
  const endingItem = startingItem + numItemsOnCurrentPage - 1
  const location = useLocation()
  const color = useColorModeValue('gray.500', 'gray.100')
  return (
    <HStack
      spacing={0.5}
      textAlign={'right'}
      position={'fixed'}
      right={2}
      top={2}
      // left={'50vw'}
      flexWrap={'nowrap'}
      justifyItems={'center'}
      align={'center'}
    >
      {children}
      <HStack spacing={0}>
        <IconButton
          as={RouterLink}
          to={location.pathname + '?' + firstPage?.toString()}
          icon={<ArrowLeftIcon />}
          size="xs"
          fontSize={11}
          variant="ghost"
          disabled={!firstPage}
        />
        <IconButton
          as={RouterLink}
          to={location.pathname + '?' + prev?.toString()}
          icon={<ChevronLeftIcon />}
          size="xs"
          fontSize={22}
          variant="ghost"
          disabled={!prev}
        />
      </HStack>
      <Text
        color={color}
      >{`${startingItem}-${endingItem} of ${totalItems}`}</Text>
      <HStack spacing={0}>
        <IconButton
          as={RouterLink}
          to={location.pathname + '?' + next?.toString()}
          icon={<ChevronRightIcon />}
          size="xs"
          fontSize={22}
          variant="ghost"
          disabled={!next}
        />
        <IconButton
          as={RouterLink}
          to={location.pathname + '?' + lastPage?.toString()}
          icon={<ArrowRightIcon />}
          size="xs"
          fontSize={11}
          variant="ghost"
          disabled={!lastPage}
        />
      </HStack>
    </HStack>
  )
}
