import React from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { JobForm } from '../NewSheet/Job2'
import { types } from '../NewSheet/layout'
import { apiFetch } from '../auth/apiFetch'
import { groups } from '../groups'
import { useUser } from '../contexts/UserProvider'
import { useModal } from '../contexts/ModalProvider'

const stripJob = job =>
  Object.fromEntries(Object.keys(types).map(key => [key, job[key]]))

export default function JobModal() {
  const { modal, setModal } = useModal()
  const sfModal = !!modal?.sfJob
  const jobNum = modal?.jobNum
  // const { latestVersionsOfJobs: jobs, setJobs } = React.useContext(JobContext)
  const [job, setJob] = React.useState(undefined)
  const [jobToCopy, setJobToCopy] = React.useState(
    sfModal ? modal.sfJob : undefined
  )
  const [modified, setModified] = React.useState(false)
  React.useEffect(() => {
    if (sfModal || jobNum) {
      const jobToCopy = modal?.job || modal?.sfJob
      let jobToCopyStripped = jobToCopy ? stripJob(jobToCopy) : undefined
      jobToCopyStripped = {
        ...jobToCopyStripped,
        sfId: modal?.sfJob?.id,
      }
      setJobToCopy(jobToCopyStripped)
      setJob(jobToCopyStripped)
    }
  }, [jobNum, sfModal])

  const handleChange = update => {
    if (Object.keys(update).every(key => `${update[key]}` === `${job[key]}`)) {
      // console.log('Skipping update, no change.', { update, oldData: job })
      return
    }
    setModified(true)
    const jobNum = update?.jobNum ?? job?.jobNum
    let groupNum = 99,
      group = 'Unassigned'
    if (jobNum) {
      if (jobNum.includes('-')) {
        groupNum = jobNum.split('-')[1].slice(0, 2)
        group = groups?.[groupNum]
        if (!group) {
          group = 'Unassigned'
          groupNum = 99
        }
      }
    }
    setJob({ ...job, ...update, groupNum, group })
  }
  const [hasCleared, setHasCleared] = React.useState(false)
  const onClose = () => {
    setModal(undefined)
    setJob(undefined)
    setHasCleared(false)
  }
  const clearDates = () => {
    setJob(prev =>
      Object.fromEntries([
        ...Object.keys(prev)
          .filter(key => key.includes('_completed'))
          .map(key => [key, false]),
        ...Object.keys(types)
          .filter(key => types[key] !== 'date')
          .filter(key => prev?.[key] !== undefined)
          .map(key => {
            if (key.includes('_completed')) return [key, false]
            return [key, prev[key]]
          }),
      ])
    )
    setHasCleared(true)
    setModified(true)
  }

  const clearAll = () => {
    setHasCleared(true)
    setJob(prev =>
      Object.fromEntries(
        Object.keys(prev)
          .filter(key => types?.[key] !== 'date')
          .map(key => {
            if (key.includes('_completed')) return [key, false]
            if (key === 'archived') return [key, false]
            switch (typeof prev[key]) {
              case 'string':
                return [key, '']
              case 'object':
                return [key, []]
              case 'boolean':
                return [key, true]
              default:
                return [key, undefined]
            }
          })
      )
    )
    setModified(true)
  }
  React.useEffect(() => {
    if (
      job &&
      jobToCopy &&
      Object.keys(job).every(key => `${job[key]}` === `${jobToCopy[key]}`) &&
      Object.keys(jobToCopy).every(key => `${job[key]}` === `${jobToCopy[key]}`)
    ) {
      setModified(false)
    }
  }, [job, jobToCopy])

  const { token, logout } = useUser()
  const toast = useToast()
  const submitJob = () => {
    apiFetch('/api/jobs', token, logout, {
      logData: true,
      logPerf: true,
      method: 'POST',
      data: job,
    })
      .then(response => {
        if (response?.error) {
          throw new Error(response?.message)
        }
        // console.log('New entry', response)
        toast({
          title: `${response.jobNum} added`,
          status: 'success',
          duration: 1500,
          isClosable: true,
          position: 'bottom-right',
        })
        onClose()
      })
      .catch(error => {
        const { message: description } = error
        alert(`Error: ${description}`)
        // toast({
        //   containerStyle: { zIndex: 9999 },
        //   title: `Error adding Job ${job.jobNum}`,
        //   description,
        //   status: 'warning',
        //   duration: 3000,
        //   isClosable: true,
        //   position: 'bottom-right',
        // })
        console.error(error)
      })
  }

  return (
    <Modal
      size={'full'}
      isOpen={!!(job && (jobNum || sfModal))}
      onClose={onClose}
      // portalProps={{ zIndex: 9999 }}
      css={{
        '.chakra-modal__content-container': {
          // zIndex: 9999,
        },
      }}
    >
      <ModalOverlay backdropFilter={'blur(10px) saturate(100%)'} />
      <ModalContent
        background={useColorModeValue('white', 'blackAlpha.900')}
        // zIndex={9999}
        sx={
          {
            // zIndex: 9999,
          }
        }
      >
        <ModalHeader>{`Add Job ${job?.jobNum ?? ''}`}</ModalHeader>
        {/*<ModalHeader>{`${*/}
        {/*  job?.jobNum === jobToCopy?.jobNum ? 'Edit' : 'Add'*/}
        {/*} Job ${job?.jobNum ?? ''}`}</ModalHeader>*/}
        <ModalCloseButton />
        <Box w={'100%'} pr={6} mb={3} display={!sfModal ? null : 'none'}>
          <Button float={'right'} onClick={clearDates} disabled={hasCleared}>
            Clear Dates
          </Button>
          <Button
            disabled={hasCleared}
            variant={'ghost'}
            mr={3}
            float={'right'}
            onClick={clearAll}
            colorScheme={'red'}
          >
            Clear All
          </Button>
        </Box>
        <ModalBody>
          <JobForm job={job} onChange={handleChange} />
          {/*<EntrySubmitButtons history={history} name={name} isModal />*/}
          <Box w={'100%'} float={'right'}>
            <Button
              float={'right'}
              colorScheme={'green'}
              disabled={!modified && !sfModal}
              onClick={submitJob}
            >
              Add Job
            </Button>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
