import React from 'react'
import { Box, Divider, Flex, useColorModeValue } from '@chakra-ui/react'
import { Nav, NavLink } from './Nav'
import { StatusSheetIcon } from '../icons'
import { StarIcon } from '@chakra-ui/icons'
import { useUser } from '../contexts/UserProvider'
import { FiUsers, SiSalesforce, SiGraphql, CgDatabase } from 'react-icons/all'
import Content, { VIEWS } from './Content'
export default function Layout({ children }) {
  const { user } = useUser()
  const { role } = user

  const isNotAdmin = !role?.[0] || role[0] < 100
  return (
    <Flex
      display="flex"
      direction="row"
      width={'100%'}
      height={'100%'}
      minH={'100%'}
      overflow={'hidden'}
      alignItems="stretch"
      borderTop={'none'}
      paddingRight={'env(safe-area-inset-right, 0px)'}
      paddingLeft={'env(safe-area-inset-left, 0px)'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Nav>
        {[
          <NavLink
            key={'status'}
            to={`/status?${new URLSearchParams(VIEWS['default'])}`}
            label={'Status Sheet'}
            LinkIcon={StatusSheetIcon}
            disabled={false}
          />,
          <NavLink
            key={'userJobs'}
            to={`/user/jobs?${new URLSearchParams(VIEWS['default'])}`}
            label={'My Jobs'}
            LinkIcon={StarIcon}
            disabled={false}
          />,
          ...(isNotAdmin
            ? []
            : [
                <Divider borderColor="gray.300" key="startAdminDiv" />,
                <NavLink
                  key={'users'}
                  to="/users"
                  label={'Users'}
                  LinkIcon={FiUsers}
                  disabled={false}
                />,
                <Divider borderColor="gray.300" key="adminDiv2" />,
                <NavLink
                  key={'salesforcejobswon'}
                  to={`/salesforcejobs?${new URLSearchParams({
                    pending: 'false',
                    archived: 'false',
                  })}`}
                  label={'Won Salesforce'}
                  LinkIcon={SiSalesforce}
                  disabled={false}
                />,
                <NavLink
                  key={'salesforcejobspending'}
                  to={`/salesforcejobs?${new URLSearchParams({
                    pending: 'true',
                    archived: 'false',
                  })}`}
                  label={'Pending Salesforce'}
                  LinkIcon={SiSalesforce}
                  disabled={false}
                />,
                <NavLink
                  key={'salesforcejobsarchived'}
                  to={`/salesforcejobs?${new URLSearchParams({
                    pending: 'false',
                    archived: 'true',
                  })}`}
                  label={'Archived Salesforce'}
                  LinkIcon={SiSalesforce}
                  disabled={false}
                />,
                <NavLink
                  key={'salesforcejobsall'}
                  to={`/salesforcejobs?${new URLSearchParams({
                    pending: 'false,true',
                    archived: 'false,true',
                  })}`}
                  label={'All Salesforce'}
                  LinkIcon={SiSalesforce}
                  disabled={false}
                />,
              ]),
          ...(user?.departments?.includes('aa')
            ? [
                <Divider borderColor="gray.300" key="aaDiv1" />,
                <NavLink
                  key={'aaStatus'}
                  to={`/department/aa`}
                  label={'AA Status'}
                  LinkIcon={SiGraphql}
                  disabled={false}
                />,
                <NavLink
                  key={'aaProjects'}
                  to={`/department/aa/projects`}
                  label={'AA Projects'}
                  LinkIcon={CgDatabase}
                  disabled={false}
                />,
              ]
            : []),
        ]}
      </Nav>
      <Box />
      <Content>{children}</Content>
    </Flex>
  )
}
