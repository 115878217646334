import { createContext, useState, useContext } from 'react'

export const RllModalContext = createContext()

export default function RllModalProvider({ children }) {
  const [rllModal, setRllModal] = useState(undefined)
  const rllModalData = {
    rllModal,
    setRllModal,
  }
  return (
    <RllModalContext.Provider value={rllModalData}>
      {children}
    </RllModalContext.Provider>
  )
}

export function useRllModal() {
  return useContext(RllModalContext)
}
