const categoryList = `
P&G Baby
P&G Beauty (APDO)
P&G Beauty (Cosmetics)
P&G Beauty (Hair)
P&G Beauty (Personal Cleansing)
P&G Beauty (Skin)
P&G Brand Operations
P&G CFI (Consumer Fundamental Insights)
P&G Country/NA Insights
P&G Customer Teams (Costco)
P&G Customer Teams (CVS)
P&G Customer Teams (Dollar General)
P&G Customer Teams (Family Dollar)
P&G Customer Teams (Food-Non Kroger)
P&G Customer Teams (Home Improvement Channel)
P&G Customer Teams (Kroger)
P&G Customer Teams (Rite Aid)
P&G Customer Teams (Sam's)
P&G Customer Teams (Target)
P&G Customer Teams (Walgreens)
P&G Customer Teams (Walmart)
P&G Fabric
P&G Family
P&G FemCare
P&G Grooming
P&G GSP (Global Selling Platforms)
P&G Home
P&G MS&P
P&G Oral Care
P&G Personal Health Care
P&G Pet Care
P&G RLL Baby
P&G RLL Beauty (APDO)
P&G RLL Beauty (Cosmetics)
P&G RLL Beauty (Hair)
P&G RLL Beauty (Personal Cleansing)
P&G RLL Beauty (Skin)
P&G RLL Brand Operations
P&G RLL CFI
P&G RLL Country/NA Insights
P&G RLL Customer Teams (Target)
P&G RLL Customer Teams (Walmart)
P&G RLL Fabric
P&G RLL Family
P&G RLL FemCare
P&G RLL Grooming
P&G RLL GSP
P&G RLL Home
P&G RLL MS&P
P&G RLL Oral Care
P&G RLL Personal Health Care
P&G RLL SMO
P&G SMO
P&G Ventures
`
  .split('\n')
  .slice(1, -1)
const serviceTypeList = `
Consultation Only
Deliverable Only (video or written)
DIY - RLL
Facility Only
Full Service
Moderation or Facilitation Only
Recruit Only
`
  .split('\n')
  .slice(1, -1)

export { categoryList, serviceTypeList }
