import { useLocation, useSearchParams } from 'react-router-dom'
import React from 'react'
import { apiFetch } from '../auth/apiFetch'
import { Box, Flex, Heading, Link, Spinner, Stack } from '@chakra-ui/react'
import Job from './Job2'
import { useUser } from '../contexts/UserProvider'
import { useModal } from '../contexts/ModalProvider'
import { useRllModal } from '../contexts/RllModalProvider'
import JobModal from '../components/JobModal'
import Pagination from './Pagination'
import JobSearch from '../components/JobSearch'
import { Link as RouterLink } from 'react-router-dom'
import { useMemo } from 'react'
import RllModal from '../components/RllModal'

export default function NewSheet() {
  let [searchParams, setSearchParams] = useSearchParams()
  // let group = searchParams.get('group') || null
  let page = searchParams.get('page') || 1
  let perPage = searchParams.get('per_page') || 5
  let search = searchParams.get('search') || ''

  let group = useMemo(() => searchParams.get('group') || null, [searchParams])
  let pending = useMemo(
    () =>
      searchParams.get('pending') !== null
        ? searchParams.get('pending').split(',')
        : ['false'],
    [searchParams]
  )
  let archived = useMemo(
    () =>
      searchParams.get('archived') !== null
        ? searchParams.get('archived').split(',')
        : ['false'],
    [searchParams]
  )
  let on_track = useMemo(
    () =>
      searchParams.get('on_track') !== null
        ? searchParams.get('on_track').split(',')
        : ['false', 'true'],
    [searchParams]
  )
  let dataCollection = useMemo(
    () =>
      searchParams.get('dataCollection') !== null
        ? searchParams.get('dataCollection').split(',')
        : ['qual', 'quant', 'unknown'],
    [searchParams]
  )
  const { logout, token } = useUser()
  const { modal } = useModal()
  const { rllModal } = useRllModal()
  const location = useLocation()

  const [jobs, setJobs] = React.useState(undefined)
  const [pagination, setPagination] = React.useState(undefined)

  const handleSearch = search => {
    searchParams.set('page', 1)
    if (search === '') {
      searchParams.delete(search)
    }
    searchParams.set('search', search)
    setSearchParams(searchParams)
  }

  React.useEffect(
    function getJobs() {
      // if (jobs !== undefined) {
      //   return
      // }
      const queryString = new URLSearchParams({
        page,
        per_page: perPage,
        archived,
        pending,
        on_track,
        dataCollection,
        group,
        ...(search.length > 0
          ? {
              search,
            }
          : {}),
      }).toString()
      const endpoint = `/api/jobs2?${queryString}`
      apiFetch(endpoint, token, logout).then(data => {
        if (!data?.items) {
          console.log('No items in data', data)
          return
        }
        setJobs(data.items)
        const { next, prev } = data._links

        let firstPage = prev ? new URLSearchParams(prev.split('?')[1]) : null
        firstPage?.set('page', 1)
        let lastPage =
          data._meta.total_pages !== data._meta.page && next
            ? new URLSearchParams(next.split('?')[1])
            : null
        lastPage?.set('page', data._meta.total_pages)

        setPagination({
          page: data._meta.page,
          perPage: data._meta.per_page,
          totalItems: data._meta.total_items,
          next: next ? new URLSearchParams(next.split('?')[1]) : null,
          prev: prev ? new URLSearchParams(prev.split('?')[1]) : null,
          firstPage,
          lastPage,
        })
      })
    },
    [
      page,
      perPage,
      archived,
      group,
      modal,
      search,
      token,
      logout,
      pending,
      on_track,
      dataCollection,
      rllModal,
    ]
  )

  return jobs ? (
    jobs?.length > 0 ? (
      <Box>
        <Pagination {...pagination} numItemsOnCurrentPage={jobs.length}>
          <JobSearch handleSubmit={handleSearch} />
        </Pagination>
        <Stack spacing={0}>
          <JobModal />
          <RllModal />
          {jobs.map(job => (
            <Job key={job.id} job={job} useAbsolute={false} width={'full'} />
          ))}
        </Stack>
      </Box>
    ) : (
      <Flex w={'full'} h={'full'} p={20} justify={'center'}>
        <Pagination {...pagination} numItemsOnCurrentPage={jobs.length} mr={2}>
          <JobSearch handleSubmit={handleSearch} />
        </Pagination>
        <Stack spacing={4}>
          <Heading textAlign={'center'}>No jobs match this criteria.</Heading>
          <Link
            variant={'ghost'}
            color={'purple.500'}
            as={RouterLink}
            fontSize={'lg'}
            textAlign={'center'}
            to={`${location.pathname}?${new URLSearchParams({
              ...Object.fromEntries(searchParams),
              page: 1,
              search: '',
            }).toString()}`}
          >
            Clear search
          </Link>
        </Stack>
      </Flex>
    )
  ) : (
    <Flex w={'full'} h={'full'} p={20} justify={'center'}>
      <Spinner
        thickness="4px"
        speed="1s"
        emptyColor="gray.200"
        color="purple.500"
        size="xl"
        sx={{ zoom: '5' }}
        opacity={0.5}
      />
    </Flex>
  )
}
