import { createContext, useState, useMemo, useContext } from 'react'

export const SearchContext = createContext()

export default function SearchProvider({ children }) {
  const [search, setSearch] = useState('')
  const searchValue = useMemo(
    () => ({
      search,
      setSearch,
    }),
    [search]
  )
  return (
    <SearchContext.Provider value={searchValue}>
      {children}
    </SearchContext.Provider>
  )
}

export function useSearch() {
  const context = useContext(SearchContext)
  return context
}
