import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useApi } from './ApiProvider'

const UserContext = createContext()

export default function UserProvider({ children }) {
  const [user, setUser] = useState()
  const api = useApi()

  useEffect(() => {
    ;(async () => {
      if (api.isAuthenticated()) {
        const response = await api.get('/me')
        setUser(response.ok ? response.body : null)
      } else {
        setUser(null)
      }
    })()
  }, [api])

  const login = useCallback(
    async magicRefreshToken => {
      const result = await api.verifyMagic(magicRefreshToken)
      if (result === 'ok') {
        const response = await api.get('/me')
        setUser(response.ok ? response.body : null)
      }
      return result
    },
    [api]
  )

  const logout = useCallback(() => {
    api.logout()
    setUser(null)
  }, [api])

  // so we don't have to change all api calls to use ApiProvider yet
  const token = localStorage.getItem('accessToken')

  return (
    <UserContext.Provider value={{ user, setUser, login, logout, token }}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  return useContext(UserContext)
}
