import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Switch,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef } from 'react'
import { apiFetch } from '../auth/apiFetch'
import { categoryList, serviceTypeList } from './rllDropdowns'
import { useUser } from '../contexts/UserProvider'
import { useRllModal } from '../contexts/RllModalProvider'

export default function RllModal() {
  const { token, logout } = useUser()
  const toast = useToast()
  const { rllModal: modal, setRllModal: setModal } = useRllModal()
  const { jobNum, job } = modal ?? { jobNum: undefined, job: undefined }
  // const [prevJob, setPrevJob] = useState(job)
  const prevJob = useRef(job)
  const onClose = () => {
    setModal()
  }

  useEffect(() => {
    prevJob.current = job
  }, [job])

  const getDataFromForm = useCallback(() => {
    let data = {
      contact: contact.current?.value,
      category: category.current?.value,
      background: background.current?.value,
      objectives: objectives.current?.value,
      serviceType: serviceType.current?.value,
      interviews: interviews.current?.value,
      researchDays: researchDays.current?.value,
      participants: participants.current?.value,
      videoDeliverable: videoDeliverable.current?.checked,
      cmk: cmk.current?.value,
      cmkAmount: cmkAmount.current?.value,
      totalRevenueProposed: totalRevenueProposed.current?.value,
      amtFirmed: amtFirmed.current?.value,
      invoicedRevenue: invoicedRevenue.current?.value,
      rllLab: rllLab.current?.value,
      shelfOwner: shelfOwner.current?.value,
    }
    return Object.fromEntries(
      Object.entries(data).flatMap(([key, value]) => {
        let prevValue = prevJob.current?.[key]
        prevValue =
          prevValue !== null && typeof prevValue !== 'boolean'
            ? `${prevValue}`
            : prevValue

        const hasBeenTouched = value !== '' || prevValue !== null
        const hasChanged = hasBeenTouched && value !== prevValue
        return hasChanged ? [[key, value]] : []
      })
    )
  }, [])
  const submitJob = () => {
    const data = getDataFromForm()
    if (!Object.keys(data).length) {
      toast({
        title: 'No changes detected.',
        status: 'warning',
        duration: 1500,
        isClosable: true,
        position: 'bottom-right',
      })
      onClose()
      return
    }
    apiFetch(`/api/jobs/${jobNum}`, token, logout, {
      method: 'PUT',
      data,
    })
      .then(response => {
        if (response?.error) {
          throw new Error(response?.message)
        } else {
          // console.log('Updated entry', response)

          toast({
            title: `${jobNum} updated`,
            status: 'success',
            duration: 1500,
            isClosable: true,
            position: 'bottom-right',
          })
          onClose()
        }
      })
      .catch(error => {
        // console.log({ error })
        setModal(prev => ({ ...prev, job: prevJob }))
        let {
          cause: { message: description },
        } = error
        if (!description) {
          description = error?.message
        }
        const title =
          error?.message && error.message.includes('403')
            ? 'Only Betsy 🤷‍♀️'
            : `${jobNum} Update Failed`
        // console.log({ description })
        toast({
          title,
          description,
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        })
      })
  }
  const borderColor = useColorModeValue('gray.100', 'gray.600')
  const inputProps = {
    color: useColorModeValue('gray.800', 'gray.200'),
    bg: useColorModeValue('gray.100', 'gray.600'),
    borderColor: borderColor,
    size: 'sm',
  }
  const labelProps = {
    fontSize: 'sm',
  }
  const formControlProps = { w: 'auto' }

  const contact = useRef()
  const category = useRef()
  const background = useRef()
  const objectives = useRef()
  const serviceType = useRef()
  const interviews = useRef()
  const researchDays = useRef()
  const participants = useRef()
  const videoDeliverable = useRef()
  const cmk = useRef()
  const cmkAmount = useRef()
  const totalRevenueProposed = useRef()
  const amtFirmed = useRef()
  const invoicedRevenue = useRef()
  const rllLab = useRef()
  const shelfOwner = useRef()

  return (
    <Modal size="3xl" isOpen={!!jobNum} onClose={onClose}>
      <ModalOverlay backdropFilter={'blur(10px) saturate(100%)'} />
      <ModalContent background={useColorModeValue('white', 'blackAlpha.900')}>
        <ModalHeader>{`${jobNum} - ${job?.description} - RLL Data`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="100%" float="right">
            <Stack spacing={5}>
              <FormControl {...formControlProps}>
                <FormLabel {...labelProps}>Contact</FormLabel>
                <Input
                  ref={contact}
                  {...inputProps}
                  maxLength={255}
                  defaultValue={job?.contact ?? ''}
                />
              </FormControl>
              <FormControl {...formControlProps}>
                <FormLabel {...labelProps}>Category</FormLabel>
                <Select
                  {...inputProps}
                  defaultValue={job?.category}
                  placeholder="Select Category"
                  ref={category}
                >
                  {categoryList.map(c => (
                    <option value={c} key={c}>
                      {c}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl {...formControlProps}>
                <FormLabel {...labelProps}>Background</FormLabel>
                <Textarea
                  {...inputProps}
                  defaultValue={job?.background}
                  size="xs"
                  rows={2}
                  ref={background}
                />
              </FormControl>
              <FormControl {...formControlProps}>
                <FormLabel {...labelProps}>Objectives</FormLabel>
                <Textarea
                  {...inputProps}
                  defaultValue={job?.objectives}
                  size="xs"
                  rows={2}
                  ref={objectives}
                />
              </FormControl>
              <FormControl {...formControlProps}>
                <FormLabel {...labelProps}>Service Type</FormLabel>
                <Select
                  {...inputProps}
                  defaultValue={job?.serviceType}
                  placeholder="Select Service Type"
                  ref={serviceType}
                >
                  {serviceTypeList.map(c => (
                    <option value={c} key={c}>
                      {c}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Stack direction={'row'} spacing={10}>
                <FormControl {...formControlProps}>
                  <FormLabel {...labelProps}># Interviews</FormLabel>
                  <NumberInput
                    {...inputProps}
                    defaultValue={job?.interviews ?? null}
                    maxW={24}
                    precision={0}
                  >
                    <NumberInputField ref={interviews} />
                    <NumberInputStepper border={'none'}>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl {...formControlProps}>
                  <FormLabel {...labelProps}># Research Days</FormLabel>
                  <NumberInput
                    {...inputProps}
                    defaultValue={job?.researchDays ?? null}
                    maxW={24}
                    precision={0}
                  >
                    <NumberInputField ref={researchDays} />
                    <NumberInputStepper border={'none'}>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl {...formControlProps}>
                  <FormLabel {...labelProps}># Participants</FormLabel>
                  <NumberInput
                    {...inputProps}
                    defaultValue={job?.participants ?? null}
                    maxW={24}
                    precision={0}
                  >
                    <NumberInputField ref={participants} />
                    <NumberInputStepper border={'none'}>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl {...formControlProps}>
                  <FormLabel htmlFor="videoDeliverable" {...labelProps}>
                    Video Deliverable?
                  </FormLabel>
                  <Switch
                    size="lg"
                    id="videoDeliverable"
                    defaultChecked={job?.videoDeliverable ?? null}
                    ref={videoDeliverable}
                  />
                </FormControl>
              </Stack>
              <Stack direction={'row'} spacing={10}>
                <FormControl w="auto">
                  <FormLabel {...labelProps}>CMK</FormLabel>
                  <Input
                    {...inputProps}
                    maxLength={20}
                    maxW={40}
                    defaultValue={job?.cmk ?? ''}
                    ref={cmk}
                  />
                </FormControl>
                <FormControl w="auto">
                  <FormLabel {...labelProps}>CMK Amount</FormLabel>
                  <NumberInput
                    {...inputProps}
                    defaultValue={job?.cmkAmount ?? null}
                    maxW={32}
                    allowMouseWheel
                    min={0}
                    max={999999999999.99}
                    step={500}
                    precision={2}
                  >
                    <NumberInputField ref={cmkAmount} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Stack>
              <Stack direction={'row'} spacing={10}>
                <FormControl w="auto">
                  <FormLabel {...labelProps}>Total Revenue Proposed</FormLabel>
                  <NumberInput
                    {...inputProps}
                    defaultValue={job?.totalRevenueProposed ?? null}
                    maxW={32}
                    allowMouseWheel
                    min={0}
                    max={999999999999.99}
                    step={500}
                    precision={2}
                  >
                    <NumberInputField ref={totalRevenueProposed} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w="auto">
                  <FormLabel {...labelProps}>Amount Firmed</FormLabel>
                  <NumberInput
                    {...inputProps}
                    defaultValue={job?.amtFirmed ?? null}
                    maxW={32}
                    allowMouseWheel
                    min={0}
                    max={999999999999.99}
                    step={500}
                    precision={2}
                  >
                    <NumberInputField ref={amtFirmed} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl w="auto">
                  <FormLabel {...labelProps}>Invoiced Revenue</FormLabel>
                  <NumberInput
                    {...inputProps}
                    defaultValue={job?.invoicedRevenue ?? null}
                    maxW={32}
                    allowMouseWheel
                    min={0}
                    max={999999999999.99}
                    step={500}
                    precision={2}
                  >
                    <NumberInputField ref={invoicedRevenue} />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Stack>
              <FormControl w="auto">
                <FormLabel {...labelProps}>RLL Lab</FormLabel>
                <Input
                  {...inputProps}
                  ref={rllLab}
                  maxLength={120}
                  maxW={810}
                  defaultValue={job?.rllLab ?? ''}
                />
              </FormControl>
              <FormControl w="auto">
                <FormLabel {...labelProps}>Shelf Owner</FormLabel>
                <Input
                  {...inputProps}
                  maxLength={120}
                  maxW={810}
                  defaultValue={job?.shelfOwner ?? ''}
                  ref={shelfOwner}
                />
              </FormControl>
            </Stack>
            {/* <Button float="right" colorScheme="green" onClick={submitJob}> */}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="red" variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button colorScheme="green" onClick={submitJob}>
            Submit RLL Data
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
