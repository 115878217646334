import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
  useEditableControls,
  useToast,
} from '@chakra-ui/react'
import React from 'react'
import { layout, specFields, types } from './layout'
import { useCombobox, useMultipleSelection } from 'downshift'
import { getUTCDate } from '../utils/dates'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import {
  ArrowDownIcon,
  CalendarIcon,
  CheckCircleIcon,
  CopyIcon,
  RepeatClockIcon,
  TimeIcon,
  WarningIcon,
} from '@chakra-ui/icons'
import { useUser } from '../contexts/UserProvider'
import { useUsers } from '../contexts/UsersProvider'
import { useModal } from '../contexts/ModalProvider'
import { useRllModal } from '../contexts/RllModalProvider'
import { apiFetch } from '../auth/apiFetch'
import { useSearchParams } from 'react-router-dom'
import { ImLab } from 'react-icons/im'
import { AnalyticsCell } from '../aa/AAHub'

dayjs.extend(relativeTime)
// function getMostRecentJob(jobs, jobNum) {
//   return jobs
//     .filter(job => job.jobNum === jobNum)
//     .sort((a, b) => b.version - a.version)[0]
// }
// const allFieldsJSONForBackend = JSON.stringify(
//   Object.fromEntries(
//     [...layout, ...specFields].flatMap(a =>
//       a?.type !== 'HeadingCell' ? [[a.attribute, a.departments]] : []
//     )
//   )
// )
// console.log({ allFieldsJSONForBackend })
export function JobForm({
  job,
  onChange,
  width,
  top,
  useAbsolute = true,
  index = undefined,
}) {
  const { modal, setModal } = useModal()
  const { rllModal, setRllModal } = useRllModal()
  // const { showArchive } = React.useContext(ShowArchiveContext)
  const [searchParams] = useSearchParams()
  const showArchive = searchParams.get('archived') === 'true'
  const handleUpdate = onChange
  const offTrackColor = useColorModeValue('red.500', 'red.300')
  // const { searchMatchIndex } = React.useContext(SearchContext)
  // const isSearchMatch = index === searchMatchIndex
  const isSearchMatch = false
  const { user } = useUser()
  const isAdmin = user?.role && user.role.some(role => role >= 100)
  const isField = user?.departments && user?.departments.includes('field')
  const isRLL = job.description.toLowerCase().includes('rll')
  const dividerColor = useColorModeValue('gray.200', 'whiteAlpha.300')
  return (
    <Box
      fontSize={'11px'}
      opacity={
        !modal &&
        !rllModal &&
        ((job?.archived === true && !showArchive) ||
        (job?.archived !== true && showArchive)
          ? 0.5
          : 1)
      }
      {...(rllModal || modal || !useAbsolute
        ? {}
        : {
            top: top,
            style: { top: top },
            css: { top: top },
            position: 'absolute',
            width: width,
          })}
    >
      <Menu>
        <MenuButton
          display={rllModal || modal ? 'none' : null}
          as={
            // <IconButton
            // />
            IconButton
          }
          size={'xs'}
          left={'104px'}
          transform={'translateY(2px)'}
          // colorScheme={'purple'}
          height={'19px'}
          w={'20px'}
          minW={'20px'}
          aria-label={'Job Menu'}
          icon={<ArrowDownIcon />}
          fontSize={'1.5em'}
          position={useAbsolute ? 'fixed' : 'absolute'}
        >
          ↓
        </MenuButton>
        <MenuList
          zIndex={'9998'}
          _css={{
            zIndex: '9998',
          }}
        >
          <MenuItem
            icon={job?.on_track ? <WarningIcon /> : <CheckCircleIcon />}
            onClick={() => handleUpdate({ on_track: !job?.on_track })}
            color={job?.on_track ? offTrackColor : null}
          >
            {job?.on_track ? 'Mark Off Track' : 'Mark On Track'}
          </MenuItem>
          {isRLL && (isField || isAdmin) ? (
            <>
              <MenuDivider color={dividerColor} />
              <MenuItem
                icon={<ImLab color={'blue.500'} />}
                onClick={() => setRllModal({ job: job, jobNum: job.jobNum })}
              >
                RLL Data
              </MenuItem>
              <MenuDivider color={dividerColor} />
            </>
          ) : null}
          {isAdmin ? (
            <>
              <MenuItem
                icon={<CopyIcon />}
                // onClick={() => setModal({ jobNum: job.jobNum })}
                onClick={() => setModal({ job: job, jobNum: job.jobNum })}
              >
                Copy
              </MenuItem>
              <MenuDivider color={dividerColor} />
              <MenuItem
                icon={<RepeatClockIcon />}
                onClick={() => handleUpdate({ archived: !job?.archived })}
              >
                {job?.archived ? 'Make Active' : 'Archive'}
              </MenuItem>
            </>
          ) : null}
        </MenuList>
      </Menu>
      <Tooltip label={`Updated ${dayjs().to(dayjs(job?.modifiedAt))}`}>
        <TimeIcon
          display={modal ? 'none' : null}
          position={'absolute'}
          transform={'translate(34px, 5.8px)'}
          color={'gray.500'}
        />
      </Tooltip>
      {!job?.on_track ? (
        <Tooltip label={`Off Track`}>
          <WarningIcon
            display={modal ? 'none' : null}
            transform={'translate(60px, 5.8px)'}
            position={'absolute'}
            color={offTrackColor}
          />
        </Tooltip>
      ) : (
        ''
      )}
      {job?.archived ? (
        <Tooltip label={`Archived`}>
          <RepeatClockIcon
            display={modal ? 'none' : null}
            position={'absolute'}
            transform={'translate(74px, 5.8px)'}
            color={'gray.500'}
          />
        </Tooltip>
      ) : (
        ''
      )}
      {job?.pending ? (
        <Tooltip label={`Pending`}>
          <CalendarIcon
            display={modal ? 'none' : null}
            position={'absolute'}
            transform={'translate(88px, 5.8px)'}
            color={'orange.500'}
            opacity={0.7}
          />
        </Tooltip>
      ) : (
        ''
      )}
      <Grid
        border={!isSearchMatch ? '1px solid' : '2px solid'}
        // borderColor={useColorModeValue('gray.200', 'gray.600')}
        borderColor={!isSearchMatch ? 'gray.200' : 'purple.400'}
        // _hover={{
        //   border: `1px solid ${useToken('colors', 'blue.600')}`,
        // }}
        width={width}
        h="220px"
        templateRows="repeat(11, 1fr)"
        templateColumns="repeat(10, 1fr)"
        gap={0}
        userSelect="text"
        cursor="auto"
        sx={{
          WebKitTapHighlightColor: 'black',
          textSizeAdjust: 'auto',
          touchAction: 'auto !important',
        }}
      >
        {layout.map(
          ({
            type,
            attribute,
            props,
            title,
            departments,
            neverFade = false,
          }) => {
            const data = job[attribute]
            const inputType = types[attribute]
            if (type === 'HeadingCell') {
              return (
                <HeadingCell
                  neverFade={neverFade}
                  attribute={attribute}
                  title={title}
                  data={data}
                  departments={departments}
                  {...props}
                  key={`${attribute}-heading`}
                />
              )
            }
            if (inputType === 'list') {
              return (
                <ListCell
                  neverFade={neverFade}
                  // options={listOptions[attribute]}
                  key={`${attribute}-list`}
                  {...props}
                  type={types[attribute]}
                  attribute={attribute}
                  departments={departments}
                  data={data}
                  {...props}
                  top={top}
                  handleUpdate={newValue => {
                    handleUpdate({ [attribute]: newValue })
                  }}
                />
              )
            }
            if (type === 'AttributeCell') {
              return (
                <AttributeCell
                  neverFade={neverFade}
                  version={job.version}
                  type={types[attribute]}
                  attribute={attribute}
                  departments={departments}
                  data={data}
                  completed={job[`${attribute}_completed`]}
                  toggleCompleted={() =>
                    handleUpdate({
                      [`${attribute}_completed`]:
                        !job[`${attribute}_completed`],
                    })
                  }
                  {...props}
                  key={attribute}
                  handleUpdate={newValue =>
                    handleUpdate({ [attribute]: newValue })
                  }
                />
              )
            }
            return null
          }
        )}
      </Grid>
      <Specs job={job} handleUpdate={handleUpdate} version={job?.version} />
    </Box>
  )
}

export const JobFormContext = React.createContext()
export default function Job({
  job: jobData,
  width,
  top,
  useAbsolute = true,
  index = undefined,
}) {
  const toast = useToast()
  const { token, logout } = useUser()

  const [job, setJob] = React.useState(jobData)
  const [prevJob, setPrevJob] = React.useState(jobData)
  // const { setJobs } = React.useContext(JobContext)

  const jobFormData = React.useMemo(() => job, [job])

  const handleUpdate = update => {
    if (Object.keys(update).every(key => `${update[key]}` === `${job[key]}`)) {
      console.log('Skipping update, no change.', { update, oldData: job })
      return
    }
    setJob({ ...job, ...update })
    apiFetch(`/api/jobs/${jobData.jobNum}`, token, logout, {
      method: 'PUT',
      data: update,
    })
      .then(response => {
        if (response?.error) {
        } else {
          console.log('Updated entry', response)
          toast({
            title: `${jobData.jobNum} updated`,
            status: 'success',
            duration: 1500,
            isClosable: true,
            position: 'bottom-right',
          })
          setPrevJob(response)
          // setJobs(prevJobs => {
          //   const matchingJob = prevJobs.find(
          //     idbJob => idbJob.id === response.id
          //   )
          //   if (matchingJob === undefined) {
          //     return [...prevJobs, response]
          //   }
          //   return prevJobs
          // })
          // set(response.jobNum, response, jobStore)
        }
      })
      .catch(error => {
        console.log({ error })
        setJob(prevJob)
        let {
          cause: { message: description },
        } = error
        if (!description) {
          description = error?.message
        }
        const title =
          error?.message && error.message.includes('403')
            ? 'Only Betsy 🤷‍♀️'
            : `${jobData.jobNum} Update Failed`
        console.log({ description })
        toast({
          title,
          description,
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        })
      })
  }

  return (
    <JobFormContext.Provider value={jobFormData}>
      <JobForm
        index={index}
        job={job}
        onChange={handleUpdate}
        width={width}
        top={top}
        useAbsolute={useAbsolute}
      />
    </JobFormContext.Provider>
  )
  // return (
  //   <Box
  //     opacity={job?.archived === true ? 0.5 : 1}
  //     top={top}
  //     style={{ top: top }}
  //     position={'absolute'}
  //     width={width}
  //   >
  //     <Menu>
  //       <MenuButton
  //         as={
  //           // <IconButton
  //           // />
  //           IconButton
  //         }
  //         size={'xs'}
  //         left={'88px'}
  //         // colorScheme={'purple'}
  //         height={'20px'}
  //         w={'20px'}
  //         minW={'20px'}
  //         aria-label={'Job Menu'}
  //         icon={<ArrowDownIcon />}
  //         fontSize={'1.5em'}
  //         position={'fixed'}
  //       >
  //         ↓
  //       </MenuButton>
  //       <MenuList>
  //         <MenuItem
  //           icon={<CopyIcon />}
  //           onClick={() => setModal({ jobNum: job.jobNum })}
  //         >
  //           Copy
  //         </MenuItem>
  //         <MenuDivider
  //           color={useColorModeValue('gray.200', 'whiteAlpha.300')}
  //         />
  //         <MenuItem
  //           icon={<RepeatClockIcon />}
  //           onClick={() => handleUpdate({ archived: true })}
  //           color={useColorModeValue('red.500', 'red.300')}
  //         >
  //           Archive
  //         </MenuItem>
  //       </MenuList>
  //     </Menu>
  //     <Grid
  //       border="1px solid"
  //       // borderColor={useColorModeValue('gray.200', 'gray.600')}
  //       borderColor={'gray.200'}
  //       // _hover={{
  //       //   border: `1px solid ${useToken('colors', 'blue.600')}`,
  //       // }}
  //       width={width}
  //       h="220px"
  //       templateRows="repeat(11, 1fr)"
  //       templateColumns="repeat(10, 1fr)"
  //       gap={0}
  //       userSelect="text"
  //       cursor="auto"
  //       sx={{
  //         WebKitTapHighlightColor: 'black',
  //         textSizeAdjust: 'auto',
  //         touchAction: 'auto !important',
  //       }}
  //     >
  //       {layout.map(({ type, attribute, props, title }) => {
  //         const data = job[attribute]
  //         const inputType = types[attribute]
  //         if (type === 'HeadingCell') {
  //           return (
  //             <HeadingCell
  //               attribute={attribute}
  //               title={title}
  //               data={data}
  //               {...props}
  //               key={`${attribute}-heading`}
  //             />
  //           )
  //         }
  //         if (inputType === 'list') {
  //           return (
  //             <ListCell
  //               options={listOptions[attribute]}
  //               type={types[attribute]}
  //               attribute={attribute}
  //               data={data}
  //               {...props}
  //               key={attribute}
  //               isScrolling={isScrolling}
  //               top={top}
  //               handleUpdate={newValue => {
  //                 handleUpdate({ [attribute]: newValue })
  //               }}
  //             />
  //           )
  //         }
  //         if (type === 'AttributeCell') {
  //           return (
  //             <AttributeCell
  //               version={job.version}
  //               type={types[attribute]}
  //               attribute={attribute}
  //               data={data}
  //               completed={job[`${attribute}_completed`]}
  //               toggleCompleted={() =>
  //                 handleUpdate({
  //                   [`${attribute}_completed`]: !job[`${attribute}_completed`],
  //                 })
  //               }
  //               {...props}
  //               key={attribute}
  //               handleUpdate={newValue =>
  //                 handleUpdate({ [attribute]: newValue })
  //               }
  //             />
  //           )
  //         }
  //         return null
  //       })}
  //     </Grid>
  //     <Specs job={job} handleUpdate={handleUpdate} />
  //   </Box>
  // )
}

function HeadingCell({ title, data, departments, neverFade, ...props }) {
  const { user } = useUser()
  const userInAnyAttributeDepartments =
    Array.isArray(departments) &&
    departments.some(
      department => user?.departments && user.departments.includes(department)
    )
  const isAdmin = user?.role && user.role.some(role => role >= 100)
  const fadeText = !neverFade && !userInAnyAttributeDepartments && !isAdmin

  return (
    <GridItem
      as={Flex}
      pl={1}
      bg={useColorModeValue('gray.100', 'whiteAlpha.200')}
      color={useColorModeValue(
        userInAnyAttributeDepartments ? 'gray.800' : 'gray.600',
        userInAnyAttributeDepartments ? 'gray.200' : 'gray.400'
      )}
      justify="center"
      alignItems="center"
      overflow="hidden"
      {...props}
    >
      <Text
        opacity={!fadeText ? 1 : 0.55}
        fontWeight={userInAnyAttributeDepartments ? 900 : 'semibold'}
        textTransform="uppercase"
        letterSpacing="wider"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {title}
      </Text>
    </GridItem>
  )
}

function SpecEditable({
  data,
  label,
  handleUpdate,
  length,
  version,
  departments,
  neverFade,
}) {
  const { user } = useUser()
  const isAdmin = user?.role && user.role.some(role => role >= 100)
  const userInAnyAttributeDepartments =
    Array.isArray(departments) &&
    user?.departments &&
    departments.some(department => user.departments.includes(department))
  const isDisabled = !isAdmin && !userInAnyAttributeDepartments
  const [value, setValue] = React.useState(data ?? '')
  const handleChange = nextValue => setValue(nextValue)
  const handleBlur = () => handleUpdate(value)

  React.useEffect(() => {
    setValue(data ?? '')
  }, [version, data])

  return (
    <Editable
      onMouseDown={ev => {
        if (isDisabled) {
          ev.preventDefault()
          ev.stopPropagation()
        }
      }}
      opacity={neverFade || isAdmin || userInAnyAttributeDepartments ? 1 : 0.75}
      isDisabled={isDisabled}
      onBlur={handleBlur}
      value={value}
      onChange={handleChange}
      as="span"
      size="xs"
      placeholder="  "
    >
      <SpecLabel
        emphasize={userInAnyAttributeDepartments}
        label={`${label}: `}
        opacity={
          neverFade || isAdmin || userInAnyAttributeDepartments ? 1 : 0.75
        }
      />
      <EditablePreview />
      <EditableInput length={length} display={'inline'} />
    </Editable>
  )
}

export function AttributeCell({
  data,
  completed,
  type,
  handleUpdate,
  toggleCompleted,
  version,
  attribute,
  isDepartmentField,
  departments,
  neverFade,
  ...props
}) {
  // const [value, setValue] = React.useState(formatForInput(data?.value, type))
  const [value, setValue] = React.useState(formatForInput(data, type))
  const handleChange = nextValue => setValue(nextValue)
  const ref = React.useRef(null)
  const completedColor = useColorModeValue('gray.500', 'whiteAlpha.600')
  const { user } = useUser()
  const { modal } = useModal()
  const userInAnyAttributeDepartments =
    Array.isArray(departments) &&
    user?.departments &&
    departments.some(department => user?.departments.includes(department))
  const isAdmin =
    Array.isArray(user?.role) && user.role.some(role => role >= 100)
  const isDisabled =
    (!modal && attribute === 'jobNum') ||
    (!isAdmin && !userInAnyAttributeDepartments)
  React.useEffect(() => {
    if (type === 'date' && ref.current && ref.current.innerText.includes('-')) {
      const currentValue = ref.current.innerText
      ref.current.innerText = formatForPreview(currentValue, 'date')
    }
  })
  React.useEffect(() => {
    setValue(formatForInput(data, type))
  }, [version, data, type])
  const handleBlur = () => {
    console.log(value, type)
    handleUpdate(formatForAPI(value, type))
  }
  return (
    <GridItem
      as={Flex}
      alignItems="center"
      pl={1}
      justify="center"
      {...props}
      overflow={'hidden'}
      fontSize="12px"
      {...(type === 'date' && !isDisabled
        ? {
            onMouseDown: e => {
              if (e.button === 2) {
                e.stopPropagation()
                e.preventDefault()
              }
            },
            onContextMenu: e => {
              e.preventDefault()
              e.stopPropagation()
              if (type === 'date') {
                toggleCompleted()
              }
            },
          }
        : isDisabled && type === 'date'
        ? {
            onMouseDown: e => {
              e.stopPropagation()
              e.preventDefault()
            },
          }
        : {})}
    >
      <Editable
        isDisabled={isDisabled}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onSubmit={() => {
          ref.current.innerText = formatForPreview(value, type) ?? ''
        }}
        as="span"
        size="xs"
        placeholder={`    `}
        w={!value || type !== 'date' ? '100%' : ''}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
        textOverflow={'ellipsis'}
        color={completed === true ? completedColor : null}
        opacity={isDisabled && !neverFade ? 0.5 : 1}
        sx={{
          // input: {
          //   border: 'none',
          //   boxSizing: 'border-box',
          //   outline: 0,
          //   padding: '.75rem',
          //   position: 'relative',
          //   width: '100%',
          // },
          //
          "&& input[type='date']::-webkit-calendar-picker-indicator": {
            // background: 'transparent',
            // bottom: 0,
            // color: 'transparent',
            // cursor: 'pointer',
            // height: 'auto',
            // left: 0,
            position: 'absolute',
            paddingLeft: '45px',
            // right: 0,
            // top: 0,
            // width: 'auto',
          },
        }}
      >
        <EditablePreview
          w={'100%'}
          ref={ref}
          textDecoration={completed === true ? 'line-through' : null}
        />
        <EditableInput
          type={type}
          fontSize={type === 'date' ? '9.5px' : null}
          _focus={{ outline: '2px solid transparent', outlineOffset: '2px' }}
          // size={'xs'}
          // sx={{ outline: '2px solid transparent', outlineOffset: '2px' }}
          // border={0}
          // p={0}
          // m={0}
          // height={'19px'}
          // pb={'2px'}
          // flex={1}
          // display={'inline'}
        />
      </Editable>
    </GridItem>
  )
}

function SpecLabel({ label, emphasize, opacity }) {
  const {
    // isEditing,
    // getSubmitButtonProps,
    // getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls()
  return (
    <Text
      {...(emphasize ? { fontWeight: 900, color: 'gray.800' } : {})}
      opacity={opacity}
      as="span"
      {...getEditButtonProps()}
    >
      {label}
    </Text>
  )
}

function Specs({ job, handleUpdate, version }) {
  return (
    <HStack
      gap={0}
      whiteSpace="pre"
      float="right"
      transform="translateY(calc(-100% - 2px))translateX(-2px)"
      height={'20px'}
      alignItems="center"
      px={1}
      fontSize="10px"
      bg={useColorModeValue('gray.100', 'gray.700')}
      color={useColorModeValue('gray.600', 'gray.300')}
    >
      {specFields.map(
        ({ attribute, length, label, departments, neverFade = false }, i) => {
          return (
            <Text key={attribute}>
              {/*<React.Fragment key={attribute}>*/}
              {attribute !== 'aaDeliverables' ? (
                <SpecEditable
                  neverFade={neverFade}
                  handleUpdate={value => handleUpdate({ [attribute]: value })}
                  data={job?.[attribute]}
                  length={length}
                  label={label}
                  version={version}
                  departments={departments}
                />
              ) : (
                <HStack key="AA Deliv." display="inline-flex">
                  <Text>AA Deliv:</Text>
                  <AnalyticsCell
                    neverFace={neverFade}
                    attribute="aaDeliverables"
                    departments={['aa']}
                    data={job?.aaDeliverables}
                    handleUpdate={newValue => {
                      handleUpdate({ aaDeliverables: newValue })
                    }}
                    colSpan={1}
                    fontSize={10}
                    pl={0}
                    display="inline"
                  />
                </HStack>
              )}
              {i !== specFields.length - 1 ? ' | ' : '  '}
              {/*</React.Fragment>*/}
            </Text>
          )
        }
      )}
    </HStack>
  )
}

export function ListControls({
  value,
  setValue,
  options,
  inputRef,
  top,
  handleBlur,
  isScrolling = true,
  display = 'block',
}) {
  // const { isScrolling } = useIsScrolling()
  const {
    isEditing,
    getSubmitButtonProps,
    // getCancelButtonProps,
    // getEditButtonProps,
  } = useEditableControls()
  const [inputValue, setInputValue] = React.useState('')
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    // removeSelectedItem,
    selectedItems,
  } = useMultipleSelection({
    initialSelectedItems: value ? value.split('/') : [],
    onStateChange: () => {
      // console.log('stateChange', selectedItems)
      setValue(selectedItems.join('/'))
    },
  })
  const getFilteredItems = () =>
    options.filter(
      item =>
        selectedItems.indexOf(item) < 0 &&
        (item
          .toLowerCase()
          .startsWith(inputValue.split('/').slice(-1)[0].toLowerCase()) ||
          item
            .toLowerCase()
            .replace('ir-', '')
            .replace('radius-', '')
            .startsWith(inputValue.split('/').slice(-1)[0]))
    )
  const {
    isOpen,
    getToggleButtonProps,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    inputValue,
    defaultIsOpen: false,
    initialIsOpen: true,
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    items: getFilteredItems(),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      // console.log('stateReducer', state, actionAndChanges)
      // eslint-disable-next-line default-case
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          setInputValue('')
          getSubmitButtonProps().onClick()
          return { isOpen: false }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: false, // keep menu open after selection.
          }
        // default:
        // console.log('type', type)
      }
      return changes
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue)
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            setInputValue('')
            addSelectedItem(selectedItem)
            selectItem(null)
            console.log({ selectedItem })
          } else {
            console.log('no selectedItem')
          }
          break
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          setInputValue('')
          getSubmitButtonProps().onClick()
          break
        case useCombobox.stateChangeTypes.InputBlur:
          setValue(selectedItems.join('/'))
          break
        default:
          // console.log('onStateChange', inputValue, type, selectedItem)
          break
      }
    },
  })
  const highlightedItemBg = useColorModeValue('gray.100', 'whiteAlpha.200')
  const toggleButtonProps = getToggleButtonProps()
  return (
    <Box position={'relative'} display={isEditing ? display : 'none'}>
      <Box position={'relative'}>
        <Flex alignItems={'center'}>
          {selectedItems.map((selectedItem, index) => (
            <Box
              whiteSpace={'nowrap'}
              as={'span'}
              // mr={1}
              key={`selected-item-${index}`}
              {...getSelectedItemProps({ selectedItem, index })}
            >
              {selectedItem}
              {'/'}
            </Box>
          ))}
          <Box
            as={'span'}
            {...getComboboxProps()}
            display={'flex'}
            flexWrap="wrap"
            alignItems="center"
          >
            <Input
              // bg={useColorModeValue('gray.100', 'whiteAlpha.100')}
              size={'xs'}
              _focus={
                {
                  // outline: '2px solid transparent',
                  // outlineOffset: '2px',
                }
              }
              sx={{ outline: '2px solid transparent', outlineOffset: '2px' }}
              border={0}
              p={0}
              m={0}
              height={'19px'}
              pb={'2px'}
              flex={1}
              display={'inline'}
              {...getInputProps(
                getDropdownProps({
                  ref: inputRef,
                  onBlur: () => {
                    setInputValue('')
                    setValue(selectedItems.join('/'))
                    getSubmitButtonProps().onClick()
                    handleBlur(selectedItems)
                  },
                  onFocus: () => {
                    console.log('focus')
                    openMenu()
                  },
                })
              )}
            />
            <button
              onMouseDown={e => {
                e.stopPropagation()
                e.preventDefault()
              }}
              {...toggleButtonProps}
              aria-label={'toggle menu'}
            >
              &#8595;
            </button>
          </Box>
          <Box
            as={'ul'}
            {...getMenuProps()}
            onMouseDown={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
            position="absolute"
            transform={`translateY(calc(50% + 7px))`}
            overflowX={'hidden'}
            overflowY={'scroll'}
            m={0}
            p={0}
            border={isOpen ? '1px solid' : null}
            color={useColorModeValue('gray.900', 'white')}
            borderColor={useColorModeValue('gray.200', 'gray.600')}
            bg={useColorModeValue('white', 'gray.700')}
            borderRadius={'sm'}
            width={'100%'}
            transition={'opacity .1s ease'}
            sx={{ listStyle: 'none' }}
            zIndex={1000}
            maxHeight={'20rem'}
            maxWidth={'10rem'}
          >
            {isOpen &&
              getFilteredItems(options).map((item, index) => (
                <Box
                  as={'li'}
                  pl={1}
                  bg={highlightedIndex === index ? highlightedItemBg : null}
                  key={`${item}${index}`}
                  {...getItemProps({ item, index })}
                >
                  {item}
                </Box>
              ))}
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export function ListCell({
  // data,
  type,
  attribute,
  top,
  handleUpdate,
  version,
  departments,
  neverFade,
  ...props
}) {
  const job = React.useContext(JobFormContext)
  const data = job?.[attribute]
  const [value, setValue] = React.useState(formatForInput(data, type))
  // const [selectedItems, setSelectedItems] = React.useState(data?.value ?? [])
  const handleChange = nextValue => setValue(nextValue)
  const inputRef = React.useRef(null)
  const handleBlur = newValue => handleUpdate(newValue)
  const {
    userLists: { [attribute]: options = [] },
  } = useUsers()

  const { user } = useUser()
  const userInAnyAttributeDepartments =
    Array.isArray(departments) &&
    departments.some(
      department => user?.departments && user?.departments.includes(department)
    )
  const isAdmin =
    Array.isArray(user?.role) && user.role.some(role => role >= 100)

  const isDepartmentField =
    (user?.departments && user?.departments.includes(attribute)) ||
    userInAnyAttributeDepartments
  const isDisabled = !isAdmin && !isDepartmentField

  React.useEffect(() => {
    // console.log(`Rerender ListCell ${job.jobNum} ${attribute}`, { data, value })
    setValue(formatForInput(data, type))
  }, [data, job?.version])
  // }, [data, type, version])

  return (
    <GridItem
      as={Flex}
      alignItems="center"
      pl={1}
      justify="center"
      {...props}
      fontSize="12px"
    >
      <Editable
        isDisabled={isDisabled}
        value={value}
        onChange={handleChange}
        as="span"
        placeholder={'    '}
        w={'100%'}
        whiteSpace={'nowrap'}
        textOverflow={'ellipsis'}
        opacity={isAdmin || isDepartmentField ? 1 : 0.55}
        sx={{ ...(isDisabled ? { pointerEvents: 'none' } : {}) }}
        onMouseDown={ev => {
          if (isDisabled) {
            ev.preventDefault()
            ev.stopPropagation()
          }
        }}
        onEdit={() => {
          if (!inputRef.current) return
          inputRef.current.focus()
        }}
        onSubmit={() => {
          if (!inputRef.current) return
          inputRef.current.blur()
        }}
      >
        <EditablePreview w={'full'} p={0} whiteSpace={'pre'} />
        {isDisabled ? (
          <>
            <EditableInput />
          </>
        ) : (
          <>
            <EditableInput display={'none'} />
            <ListControls
              data={data}
              value={value}
              setValue={setValue}
              handleBlur={handleBlur}
              // setSelectedItems={setSelectedItems}
              options={options}
              inputRef={inputRef}
              top={top}
            />
          </>
        )}
      </Editable>
    </GridItem>
  )
}

export function formatForInput(value, type) {
  switch (type) {
    case 'date':
      if (!value) return undefined
      const date = new Date(value)
      const day = `0${date.getDate()}`.slice(-2)
      const month = `0${date.getMonth() + 1}`.slice(-2)
      let year = date.getFullYear()
      if (value.includes('/')) {
        const [month, , includedYear] = value.split('/')
        if (!includedYear) {
          const now = new Date()
          if (now.getMonth() >= month) {
            year = now.getFullYear() + 1
          }
        }
      }
      return `${year}-${month}-${day}`
    case 'list':
      if (!value) return ''
      if (Array.isArray(value)) {
        return value.join('/')
      }
      return value
    default:
      return value
  }
}

function formatForPreview(value, type) {
  switch (type) {
    case 'date':
      if (!value) return undefined
      const date = getUTCDate(value)
      const day = `${date.getDate()}`
      const month = `${date.getMonth() + 1}`
      return `${month}/${day}`

    default:
      return value
  }
}

function formatForAPI(value, type) {
  switch (type) {
    case 'date':
      if (!value) return null
      const date = getUTCDate(value)
      const day = `${date.getDate()}`
      const month = `${date.getMonth() + 1}`
      const year = `${date.getFullYear()}`
      return `${month}/${day}/${year}`

    default:
      return value
  }
}
