export const layout = [
  {
    type: 'HeadingCell',
    attribute: 'jobNum',
    title: 'Job',
    props: { justify: 'start' },
    departments: [],
    neverFade: true,
  },
  {
    type: 'HeadingCell',
    attribute: 'description',
    title: 'Description',
    props: { colSpan: 3 },
    departments: [],
    neverFade: true,
  },
  {
    type: 'HeadingCell',
    attribute: 'mailDate',
    title: 'Email Date',
    departments: ['field'],
  },
  {
    type: 'HeadingCell',
    attribute: 'startDate',
    title: 'Start Date',
    departments: ['field'],
  },
  {
    type: 'HeadingCell',
    attribute: 'partialInDate',
    title: 'Partial In Date',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'fieldFinish',
    title: 'Field Finish',
    departments: ['field'],
  },
  {
    type: 'HeadingCell',
    attribute: 'allInHouse',
    title: 'All in House',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'tabPlanToDp',
    title: 'Tab Plan To DP',
    departments: [],
  },
  // Second Row
  {
    type: 'AttributeCell',
    attribute: 'jobNum',
    props: { justify: 'start', sx: { '& input': { width: '120px' } } },
    departments: [],
    neverFade: true,
  },
  {
    type: 'AttributeCell',
    attribute: 'description',
    props: { colSpan: 3, justify: 'start' },
    departments: [],
    neverFade: true,
  },
  {
    type: 'AttributeCell',
    attribute: 'mailDate',
    departments: ['field'],
  },
  {
    type: 'AttributeCell',
    attribute: 'startDate',
    departments: ['field'],
  },
  {
    type: 'AttributeCell',
    attribute: 'partialInDate',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'fieldFinish',
    departments: ['field'],
  },
  {
    type: 'AttributeCell',
    attribute: 'allInHouse',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'tabPlanToDp',
    departments: [],
  },
  // Third Row
  {
    type: 'HeadingCell',
    attribute: 'client',
    title: 'Client',
    props: { justify: 'start' },
    departments: [],
    neverFade: true,
  },
  {
    type: 'AttributeCell',
    attribute: 'client',
    props: { colSpan: 2, justify: 'start' },
    departments: [],
    neverFade: true,
  },
  {
    type: 'HeadingCell',
    attribute: 'fieldAlert',
    title: 'Field Alert',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'finalCodes',
    title: 'Final Codes',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'oeDataToDp',
    title: 'OE Data to DP',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'ceTabsToRa',
    title: 'CE Tabs to RA',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'finalTabsToRa',
    title: 'Final Tabs to RA',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'fileToAa',
    title: 'File to AA',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'spssFileToRa',
    title: 'SPSS file to RA',
    departments: [],
  },
  // Fourth Row
  {
    type: 'HeadingCell',
    attribute: 'research',
    title: 'RA',
    props: { justify: 'start' },
    departments: ['research'],
  },
  {
    type: 'AttributeCell',
    attribute: 'research',
    props: { colSpan: 2, justify: 'start' },
    departments: ['research'],
  },
  {
    type: 'AttributeCell',
    attribute: 'fieldAlert',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'finalCodes',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'oeDataToDp',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'ceTabsToRa',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'finalTabsToRa',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'fileToAa',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'spssFileToRa',
    departments: [],
  },
  // Fifth Row
  {
    type: 'HeadingCell',
    attribute: 'field',
    title: 'Field/PD',
    props: { justify: 'start' },
    departments: ['field'],
  },
  {
    type: 'AttributeCell',
    attribute: 'field',
    props: { colSpan: 3, justify: 'start' },
    departments: ['field'],
  },
  {
    type: 'HeadingCell',
    attribute: 'toplineToClient',
    title: 'Topline to Client',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'reportToClient',
    title: 'Report to Client',
    departments: ['field'],
  },
  {
    type: 'HeadingCell',
    attribute: 'presentation',
    title: 'Presentation',
    departments: ['field'],
  },
  {
    type: 'HeadingCell',
    attribute: 'worksession',
    title: 'Worksession',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'followUp',
    title: 'Follow-up',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'otherClientDate',
    title: 'Other Client Date',
    departments: [],
  },
  // Sixth Row
  {
    type: 'HeadingCell',
    attribute: 'programming',
    title: 'Programmer',
    props: { justify: 'start' },
    departments: ['programming'],
  },
  {
    type: 'AttributeCell',
    attribute: 'programming',
    props: { colSpan: 3, justify: 'start' },
    departments: ['programming'],
  },
  {
    type: 'AttributeCell',
    attribute: 'toplineToClient',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'reportToClient',
    departments: ['field'],
  },
  {
    type: 'AttributeCell',
    attribute: 'presentation',
    departments: ['field'],
  },
  {
    type: 'AttributeCell',
    attribute: 'worksession',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'followUp',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'otherClientDate',
    departments: [],
  },
  // Seventh Row
  {
    type: 'HeadingCell',
    attribute: 'aa',
    title: 'AA',
    props: { justify: 'start' },
  },
  {
    type: 'AttributeCell',
    attribute: 'aa',
    props: { colSpan: 3, justify: 'start' },
    departments: ['aa'],
  },
  {
    type: 'HeadingCell',
    attribute: 'dcmDesign',
    title: 'DCM Design',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'maxDiffDesign',
    title: 'Max Diff Design',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'drivers',
    title: 'Drivers',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'segmentation',
    title: 'Segmentation',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'simulatorToRa',
    title: 'Simulator to RA',
    departments: [],
  },
  {
    type: 'HeadingCell',
    attribute: 'otherAa',
    title: 'Other AA',
    departments: [],
  },
  // Eigth Row
  {
    type: 'HeadingCell',
    attribute: 'coding',
    title: 'Coding',
    props: { justify: 'start' },
    departments: ['coding'],
  },
  {
    type: 'AttributeCell',
    attribute: 'coding',
    props: { colSpan: 3, justify: 'start' },
    departments: ['coding'],
  },
  {
    type: 'AttributeCell',
    attribute: 'dcmDesign',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'maxDiffDesign',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'drivers',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'segmentation',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'simulatorToRa',
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'otherAa',
    departments: [],
  },
  // ninth row
  {
    type: 'HeadingCell',
    attribute: 'dp',
    title: 'DP',
    props: { justify: 'start' },
    departments: [],
  },
  {
    type: 'AttributeCell',
    attribute: 'dp',
    props: { colSpan: 9, justify: 'start' },
    departments: [],
  },
  // tenth row
  {
    type: 'HeadingCell',
    attribute: 'moderation',
    title: 'Moderator',
    props: { justify: 'start' },
    departments: ['field'],
  },
  {
    type: 'AttributeCell',
    attribute: 'moderation',
    props: { colSpan: 9, justify: 'start' },
    departments: ['field'],
  },
  // eleventh row
  {
    type: 'HeadingCell',
    attribute: 'comments',
    title: 'Comments',
    props: { justify: 'start' },
    departments: ['field'],
    neverFade: true,
  },
  {
    type: 'AttributeCell',
    attribute: 'comments',
    props: { colSpan: 9, justify: 'start' },
    departments: ['field'],
    neverFade: true,
  },
]

export const types = {
  jobNum: 'text',
  description: 'text',
  client: 'text',
  research: 'list',
  field: 'list',
  programming: 'list',
  aa: 'list',
  coding: 'list',
  dp: 'list',
  aaDeliverables: 'text',
  moderation: 'list',
  sampleSize: 'text',
  length: 'text',
  methods: 'text',
  aos: 'text',
  oes: 'text',
  cards: 'text',
  banners: 'text',
  comments: 'text',
  mailDate: 'date',
  startDate: 'date',
  partialInDate: 'date',
  fieldFinish: 'date',
  allInHouse: 'date',
  tabPlanToDp: 'date',
  fieldAlert: 'date',
  finalCodes: 'date',
  oeDataToDp: 'date',
  ceTabsToRa: 'date',
  finalTabsToRa: 'date',
  fileToAa: 'date',
  spssFileToRa: 'date',
  toplineToClient: 'date',
  reportToClient: 'date',
  presentation: 'date',
  worksession: 'date',
  followUp: 'date',
  otherClientDate: 'date',
  dcmDesign: 'date',
  maxDiffDesign: 'date',
  drivers: 'date',
  segmentation: 'date',
  simulatorToRa: 'date',
  otherAa: 'date',
}

export const listOptions = {
  research: [
    'Unassigned',
    'Alex',
    'Ali',
    'Alice',
    'Amanda',
    'Anita',
    'Ariene',
    'Bari',
    'Ben',
    'Brandon',
    'Brenda',
    'Chris B.',
    'Chris Breheim',
    'Christina',
    'Christine',
    'Collin',
    'Cori',
    'Crystal',
    'Curtis',
    'Dana',
    'Daniele',
    'Debora',
    'Elizabeth',
    'Ellen',
    'Erica',
    'Greg',
    'Jamie',
    'Kaitlin',
    'Kate',
    'Kathy R.',
    'Kathy T.',
    'Kelby',
    'Keri',
    'Kristina',
    'Julia',
    'Laura',
    'Lisa',
    'Mandy',
    'Maya',
    'Meghan',
    'Melinda',
    'Melissa',
    'Michael',
    'Michelle B.',
    'Michelle D.',
    'Natalie',
    'Nicole',
    'Shayna',
    'Sheri',
    'Siobhan',
    'Stacy',
    'Tawnya',
    'Usha',
  ],
  field: [
    'Unassigned',
    'Kim',
    'Bobby',
    'Crystal',
    'Michelle J.',
    'Christian',
    'Andrew',
    'Camilo',
    'Neetika',
    'IR',
    'IR-Alice',
    'NA',
    'IR-Amanda',
    'IR-Anna',
    'IR-Chris',
    'IR-Jennifer',
    'IR-Mary',
    'IR-Sheri',
    'IR-Stacy',
  ],
  programming: [
    'Unassigned',
    'Radius-Tara',
    'Radius-VC Balaji',
    'Radius-Kamal',
    'Radius-Saravanan',
    'Radius-Shamila',
    'Radius-Pranjal',
    'Radius',
    'Ugam',
    "O'donell",
    'MKTG',
    'Savanta',
    'ISS',
    'TTC',
    'PMR',
    'Online',
    'Decipher',
    'Bayasoft',
    'Intergam',
    'PMR',
    'NA ',
  ],
  aa: ['Unassigned', 'NA', 'Raman', 'Jackie', 'Scott', 'Sonia'],
  coding: [
    'Unassigned',
    'Intellisync',
    'Greg Pierce',
    'Adapt',
    'PMR',
    'Coding Experts',
    'NA ',
  ],
  dp: [
    'Unassigned',
    'Radius-Omar',
    'Radius-Kit',
    'Radius-Omkar',
    'Radius-Kersi',
    'Radius-Shamila',
    'Radius',
    'Intellisync',
    'Abigail',
    'Ruth',
    'NA',
    'PMR',
    'ISS',
    'Asmart',
  ],
  moderation: [
    'Amy',
    'Bari',
    'Christine H',
    'Coleman',
    'Jodi',
    'Kathy',
    'Laura H',
    'Leslie',
    'Maureen',
    'Savannah',
    'Susan',
    'Travis',
  ],
}

export const specFields = [
  {
    attribute: 'sampleSize',
    length: '6',
    label: 'Sample Size',
    departments: ['field'],
  },
  {
    attribute: 'length',
    length: '4',
    label: 'Length',
    departments: [],
  },
  {
    attribute: 'methods',
    length: '7',
    label: 'Methods',
    departments: ['field'],
  },
  {
    attribute: 'oes',
    length: '3',
    label: '#OES',
    departments: [],
  },
  {
    attribute: 'aos',
    length: '3',
    label: '#A/Os',
    departments: [],
  },
  {
    attribute: 'banners',
    length: '3',
    label: '#Ban',
    departments: [],
  },
  {
    attribute: 'aaDeliverables',
    length: '7',
    label: 'AA Deliv',
    departments: [],
  },
]
