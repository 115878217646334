import { Icon } from '@chakra-ui/react'
export default function RadiusLogo(props) {
  return (
    <Icon
      viewBox={'0.0 0.0 424.24146981627297 424.24146981627297'}
      color={'orange.500'}
      {...props}
    >
      <g>
        {/*<path fill="#000000" d="m0 0l424.24146 0l0 424.24146l-424.24146 0z" />*/}
        <path
          // fill="#f06537"
          fill={'currentColor'}
          d="m0 212.29921l0 0c0 -117.24961 95.0496 -212.29921 212.29921 -212.29921l0 0c56.305252 0 110.3044 22.367174 150.11821 62.181c39.813843 39.813828 62.181 93.81296 62.181 150.11821l0 0c0 117.24962 -95.04959 212.29921 -212.29921 212.29921l0 0c-117.24961 0 -212.29921 -95.04959 -212.29921 -212.29921z"
          // fill-rule="evenodd"
        />
        {/*<path*/}
        {/*  fill="#000000"*/}
        {/*  fill-opacity="0.0"*/}
        {/*  d="m52.157482 76.68767l273.98425 0l0 181.32283l-273.98425 0z"*/}
        {/*  fill-rule="evenodd"*/}
        {/*/>*/}
        <path
          fill="white"
          d="m139.01686 359.34903l0 -259.20312l36.265625 0l3.734375 61.34375l1.609375 0q20.265625 -31.46875 49.859375 -49.59375q29.60936 -18.140625 65.874985 -18.140625q14.390625 0 26.65625 2.40625q12.265625 2.390625 24.53125 8.25l-10.125 37.875q-13.875 -4.796875 -23.203125 -6.921875q-9.328125 -2.140625 -24.796875 -2.140625q-29.328125 0 -56.26561 16.796875q-26.9375 16.796875 -50.40625 57.328125l0 151.99998l-43.734375 0z"
          // fill-rule="nonzero"
        />
      </g>
    </Icon>
  )
}
