import React, { useMemo } from 'react'
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Kbd,
  useColorModeValue,
  useEventListener,
  IconButton,
} from '@chakra-ui/react'
import { SearchIcon, CloseIcon } from '@chakra-ui/icons'
import { useSearchParams } from 'react-router-dom'

// https://github.com/chakra-ui/chakra-ui-docs/blob/main/src/components/omni-search.tsx
const ACTION_KEY_DEFAULT = ['Ctrl', 'Control']
const ACTION_KEY_APPLE = ['⌘', 'Command']

const getIsMac = navigator =>
  /(Mac|iPhone|iPod|iPad)/i.test(navigator?.platform)

export default function JobSearch({
  // searchInput: search,
  // setSearchInput: setSearch,
  handleSubmit,
  ...props
}) {
  const [actionKey, setActionKey] = React.useState(ACTION_KEY_APPLE)
  React.useEffect(() => {
    if (typeof navigator === 'undefined') return
    const isMac = getIsMac(navigator)
    if (!isMac) {
      setActionKey(ACTION_KEY_DEFAULT)
    }
  }, [])

  const inputRef = React.useRef()
  useEventListener('keydown', event => {
    const isMac = getIsMac(navigator)
    const hotkey = isMac ? 'metaKey' : 'ctrlKey'
    if (event?.key?.toLowerCase() === 'k' && event[hotkey]) {
      event.preventDefault()
      inputRef.current.focus()
    }
  })
  const [searchParams] = useSearchParams()
  let search = useMemo(() => searchParams.get('search') || '', [searchParams])

  // const { searchMatchIndex, setSearchMatchIndex } =
  //   React.useContext(SearchContext)

  return (
    <form
      {...props}
      onSubmit={e => {
        e.preventDefault()
        handleSubmit(inputRef.current.value)
      }}
    >
      <InputGroup>
        <InputLeftElement
          pointerEvents={'none'}
          children={<SearchIcon />}
          color={useColorModeValue('gray.500', 'whiteAlpha.600')}
        />
        <Input
          ref={inputRef}
          autoCorrect="off"
          spellCheck="false"
          maxLength={64}
          id="search-input"
          placeholder={search === '' ? 'Search Jobs' : search}
          // value={search}
          // onChange={e => {
          //   setSearch(e.target.value)
          // }}
          mr={2}
          minW={'250px'}
          variant={'filled'}
        />
        <InputRightElement
          right={getIsMac(navigator) ? '30px' : '41px'}
          children={
            <HStack spacing={0.5}>
              <IconButton
                icon={<CloseIcon />}
                size="xs"
                colorScheme="red"
                variant="ghost"
                disabled={!search}
                onClick={() => {
                  handleSubmit('')
                  inputRef.current.value = ''
                }}
              />
              <Kbd
                pointerEvents="none"
                color={useColorModeValue('gray.500', 'whiteAlpha.600')}
                rounded="2px"
                bg={useColorModeValue('gray.200', 'whiteAlpha.200')}
                border={'1px solid'}
                borderBottom={'3px solid'}
                borderColor={useColorModeValue('gray.300', 'whiteAlpha.300')}
              >
                <Box
                  as="abbr"
                  title={actionKey[1]}
                  textDecoration="none !important"
                >
                  {actionKey[0]}
                </Box>
              </Kbd>
              <Kbd
                color={useColorModeValue('gray.500', 'whiteAlpha.600')}
                rounded="2px"
                bg={useColorModeValue('gray.200', 'whiteAlpha.200')}
                border={'1px solid'}
                borderBottom={'3px solid'}
                borderColor={useColorModeValue('gray.300', 'whiteAlpha.300')}
              >
                K
              </Kbd>
            </HStack>
          }
        />
      </InputGroup>
    </form>
  )
}
