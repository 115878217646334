import React from 'react'
import { useUser } from '../contexts/UserProvider'
import { Navigate } from 'react-router-dom'

export default function RequireRole({ children, role = 100 }) {
  const { user } = useUser()
  if (user === undefined) {
    return null
  }
  if (role <= Math.max(...user.role)) {
    return children
  }
  return <Navigate to="/" />
}
