import React from 'react'

import {
  Avatar,
  Box,
  Button,
  Flex,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'

import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from 'react-router-dom'

import RadiusLogo from './RadiusLogo'
import { ToggleGroup, ToggleGroupItem } from './ToggleGroup'
import { apiFetch } from '../auth/apiFetch'
import { ExternalLinkIcon, LockIcon } from '@chakra-ui/icons'
import { FaMoon, FaSun } from 'react-icons/fa'
import { groups } from '../groups'
import { useUser } from '../contexts/UserProvider'

export function NavLink({ to, label, LinkIcon, ...props }) {
  return (
    <LinkBox>
      <LinkOverlay href={to}>
        <Button
          as="span"
          flexGrow={1}
          size="sm"
          variant="ghost"
          display="flex"
          leftIcon={<LinkIcon />}
          justifyContent="start"
          px={2}
          py={0}
          {...props}
          // height="27"
          // fontWeight={500}
          // fontSize="13px"
        >
          {label}
        </Button>
      </LinkOverlay>
    </LinkBox>
  )
}

export function Nav({ children }) {
  const { colorMode, toggleColorMode } = useColorMode()
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)
  const borderColor = useColorModeValue('gray.100', 'gray.700')
  // const {
  //   groups,
  //   activeGroups: activeActiveGroups,
  //   archivedActiveGroups,
  // } = React.useContext(JobContext)
  const [searchParams] = useSearchParams()
  // const showArchive = searchParams.get('archived') === 'true'
  // const showPending = searchParams.get('pending') === 'true'
  // const activeGroups = showArchive ? archivedActiveGroups : activeActiveGroups
  const activeGroups = groups
  const activeGroup = searchParams.get('group') || '01'
  const location = useLocation()
  const { token, logout, user } = useUser()
  const toast = useToast()
  const [gettingExport, setGettingExport] = React.useState(false)
  const [disableExport, setDisableExport] = React.useState(false)
  const handleDownloadExport = () => {
    setGettingExport(true)
    apiFetch(`/api/jobs/export_reports`, token, logout)
      .then(data => {
        // const href = window.URL.createObjectURL(data)
        // const a = document.createElement('a')
        // const stamp = getTimestamp()
        // a.download = `Job Reports ${stamp}.zip`
        // a.href = href
        // a.click()
        // a.href = ''
        // console.log({ data })
        toast({
          title: data?.title,
          description: data?.description,
          status: 'success',
          duration: 6000,
          isClosable: true,
          position: 'bottom-right',
        })
        setGettingExport(false)
        setDisableExport(true)
      })
      .catch(err => {
        // console.log(err)
        setGettingExport(false)
      })
  }
  const { role } = user
  const userName = user?.email ? user?.email.split('@')[0] : ''
  const isNotAdmin = !role?.[0] || role[0] < 100
  return (
    <Flex
      as={'nav'}
      direction="column"
      userSelect={'none !important'}
      width={220}
      position={'relative'}
      height={'100%'}
      maxWidth={330}
      minW={220}
      boxSizing={'border-box'}
      flexShrink={0}
      borderRight={'1px solid'}
      borderColor={borderColor}
    >
      <Flex
        id={'top-nav'}
        direction={'column'}
        py={3}
        px={5}
        flex={'initial'}
        alignItems={'stretch'}
      >
        <Flex
          id={'app-info-container'}
          direction={'row'}
          flex={'1 1 auto'}
          alignItems={'center'}
          mr={-2}
          ml={-1.5}
        >
          <Flex
            id={'app-info'}
            maxWidth={'100%'}
            flex={'1 1 auto'}
            direction={'row'}
            alignItems={'center'}
          >
            <Menu>
              <MenuButton
                as={Button}
                loadingText={'Requesting Export'}
                isLoading={gettingExport}
                id={'app-menu-button'}
                aria-label={'Menu'}
                variant={'ghost'}
                size={'sm'}
                leftIcon={<RadiusLogo boxSize={18} />}
                p={'7px'}
                m={0}
                textOverflow={'ellipsis'}
                // whiteSpace={'nowrap'}
              >
                Radius
              </MenuButton>
              <Portal>
                <MenuList
                  fontSize={'sm'}
                  backdropFilter={'blur(15px) saturate(100%)'}
                >
                  <MenuItem onClick={toggleColorMode} icon={<SwitchIcon />}>
                    Toggle {colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
                  </MenuItem>
                  {isNotAdmin && false
                    ? null
                    : [
                        <MenuItem
                          key={'reports'}
                          icon={<ExternalLinkIcon />}
                          isDisabled={gettingExport || disableExport}
                          onClick={ev => {
                            ev.preventDefault()
                            ev.stopPropagation()
                            handleDownloadExport()
                          }}
                        >
                          Export Reports
                        </MenuItem>,
                      ]}
                  <MenuDivider
                    color={useColorModeValue('gray.200', 'whiteAlpha.300')}
                  />
                  <MenuItem
                    onClick={logout}
                    icon={<LockIcon />}
                    color={useColorModeValue('red.500', 'red.300')}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
            <Flex
              minW={8}
              flexShrink={'initial'}
              flexBasis={'initial'}
              direction={'row'}
              flexGrow={1}
            />
            <Box cursor={'pointer'}>
              <Avatar
                display={'flex'}
                alignItems={'center'}
                name={userName ? `${userName[0]} ${userName.slice(1)}` : ''}
                size={'xs'}
                p={2}
                w={'18px'}
                h={'18px'}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        direction={'column'}
        flex={'initial'}
        flexGrow={1}
        overflowY={'auto'}
        py={0}
        px={4}
        mb={0.5}
      >
        <Flex flex="initial" display="block">
          {children}
        </Flex>
      </Flex>
      <Flex
        flexShrink={'initial'}
        flexBasis={'initial'}
        direction={'row'}
        flexGrow={1}
        id={'nav-filler'}
        alignItems={'flex-end'}
      />
      {/*{!modal &&*/}
      {['status', 'app', 'status2'].includes(
        location.pathname.split('/').slice(-1)[0]
      ) ? (
        <ToggleGroup
          type={'single'}
          aria-label={'Group Selector'}
          colorScheme={'purple'}
          size={'sm'}
          direction={'column'}
          // ml={2}
          // mb={2}
        >
          {Object.keys(activeGroups)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .map(groupNum => {
              const groupSearchParams = searchParams
              groupSearchParams.set('group', groupNum)
              groupSearchParams.set('page', '1')
              // if (groupSearchParams.get('search')) {
              //   groupSearchParams.delete('search')
              // }
              return (
                <ToggleGroupItem
                  value={groupNum}
                  key={groupNum}
                  asChild={true}
                  tooltip={
                    groups[groupNum]?.split(' ')?.[1] ?? groups[groupNum]
                  }
                  tooltipOpen={groupNum === activeGroup ? true : undefined}
                  portalProps={{
                    sx: groupNum === activeGroup ? { zIndex: 10 } : {},
                  }}
                >
                  <RouterLink
                    // to={`${location.pathname}?group=${groupNum}&archived=${showArchive}&pending=${showPending}`}
                    to={`${location.pathname}?${searchParams}`}
                    state={{ from: 'toggleGroup' }}
                  >
                    {groupNum}
                  </RouterLink>
                </ToggleGroupItem>
              )
            })}
        </ToggleGroup>
      ) : null}
    </Flex>
  )
}
