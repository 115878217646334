import React from 'react'
import {
  Box,
  IconButton,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { CloseIcon, EditIcon } from '@chakra-ui/icons'
import UserModal from './UserModal'
import { VIEWS } from '../components/Content'
import { Link as RouterLink } from 'react-router-dom'
import { useUser } from '../contexts/UserProvider'
import { apiFetch } from '../auth/apiFetch'
import { useUsers } from '../contexts/UsersProvider'
import { useModal } from '../contexts/ModalProvider'

export default function Users() {
  const { logout, token } = useUser()
  const { userFilter, userLists, users, setUsers } = useUsers()
  const { setModal } = useModal()
  const subtleGray = useColorModeValue('gray.500', 'gray.400')
  const filteredUsers = userFilter
    ? users.filter(u => userLists[userFilter].includes(u.name_for_status))
    : users

  React.useEffect(() => {
    apiFetch('/api/users', token, logout, {
      // logData: true,
      // logPerf: true,
    }).then(apiUserData => setUsers(apiUserData))
  }, [logout, setUsers, token])
  // return users.map(user => user.name_for_status).join(', ')
  return users !== undefined ? (
    <Box w={'full'}>
      <UserModal />
      <Table>
        <Thead>
          <Th>Edit</Th>
          <Th>Name</Th>
          <Th>Email</Th>
          <Th>Jobs</Th>
          <Th>Active</Th>
        </Thead>
        <Tbody>
          {filteredUsers.map((user, u) => (
            <Tr key={u} color={user?.active === false ? subtleGray : null}>
              <Td>
                <IconButton
                  color={subtleGray}
                  size={'sm'}
                  variant={'ghost'}
                  aria-label={'edit user'}
                  icon={<EditIcon />}
                  onClick={() => setModal({ userId: user.id })}
                />
              </Td>
              <Td>{user.name_for_status}</Td>
              <Td>{user.email}</Td>
              <Td>
                {user.num_jobs > 0 ? (
                  <>
                    <Link
                      // color={'purple.400'}
                      as={RouterLink}
                      to={`/user/${user.id}/jobs?${new URLSearchParams(
                        VIEWS['default']
                      )}`}
                    >
                      View Jobs
                    </Link>
                    <Text
                      fontSize="small"
                      as="span"
                      color={user?.active ? 'gray.400' : 'gray.300'}
                    >
                      {' '}
                      ({user.num_jobs})
                    </Text>
                  </>
                ) : null}
              </Td>
              <Td>
                {user?.active === false ? <CloseIcon fontSize={'sm'} /> : ''}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  ) : (
    'Loading users...'
  )
}
