import React from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  Flex,
  FormControl,
  Heading,
  Icon,
  Input,
  Stack,
  theme,
  useColorModeValue,
} from '@chakra-ui/react'
import { ColorModeSwitcher } from '../../components/ColorModeSwitcher'
import { FaCheckCircle } from 'react-icons/all'
import { EmailIcon } from '@chakra-ui/icons'
import { emailRegex } from './utils/regex'
import { useLocation } from 'react-router-dom'

const invalidEmailMessage = 'Please enter a valid email'

export default function MagicLinkForm({ message, setMessage }) {
  const emailInput = React.useRef()
  const [error, setError] = React.useState('')
  const [sent, setSent] = React.useState(false)
  const [validEmail, setValidEmail] = React.useState(false)

  let location = useLocation()

  const requestMagicLink = email => {
    document.body.style.cursor = 'progress'
    setSent('requesting')
    let status = 0
    let next = '/'
    if (location.state && location.state.next) {
      next = location.state.next
    }
    return fetch('/api/get_magic_link', {
      headers: {
        Authorization: 'Basic ' + btoa(`${email}:none`),
      },
      referrer: next,
      credentials: 'omit',
    })
      .then(res => {
        status = res.status
        if (status === 500) {
          throw new Error('Unable to contact server. Please try again.')
        } else if (status === 401) {
          setError(res.statusText)
          setSent(false)
        } else {
          return 'Magic link sent. Please check your email.'
        }
      })
      .then(response => {
        if (!response) {
          return
        }
        document.body.style.cursor = 'default'
        if (status === 204) {
          setSent(true)
          // setMessage(response.message);
          return response
        } else if (status === 401) {
          setMessage(response.message)
        } else if (response.error !== undefined) {
          throw new Error(response.error)
        }
      })
      .catch(error => {
        document.body.style.cursor = 'default'
        setError(error.message)
        setSent(false)
        throw error
      })
      .finally(() => (document.body.style.cursor = 'default'))
  }

  const handleChange = ev => {
    if (sent) {
      return
    }
    setValidEmail(prevValidEmail => {
      const currValid = emailRegex.test(emailInput.current.value)
      if (currValid && !prevValidEmail) {
        if (error === invalidEmailMessage) {
          setError('')
        }
      }
      return currValid
    })
    if (ev.type === 'keyup' && ev.key !== 'Enter') {
      return
    } else if (
      emailInput.current.value.length === 0 ||
      !emailRegex.test(emailInput.current.value)
    ) {
      setError(invalidEmailMessage)
    } else {
      setError('')
      requestMagicLink(emailInput.current.value).catch(({ message }) => {
        console.log(message)
      })
    }
  }
  const validEmailColor = useColorModeValue('green.400', 'green.700')

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      py={12}
      as={'main'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <ColorModeSwitcher position={'absolute'} top={5} right={5} />
      <Stack
        boxShadow={'2xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        p={10}
        spacing={8}
        align={'center'}
        minWidth={{ base: 'xs', md: 'sm' }}
      >
        <Icon
          w={24}
          h={24}
          viewBox="0 0 200 200"
          // color={'orange.600'}
          // gradient="linear(to-r, green.200, pink.500)"
        >
          <defs>
            {/*<linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="100%">*/}
            {/*  <stop*/}
            {/*    offset="0%"*/}
            {/*    style={{ stopColor: theme.colors.orange[300], stopOpacity: 1 }}*/}
            {/*  />*/}
            {/*  <stop*/}
            {/*    offset="100%"*/}
            {/*    style={{ stopColor: theme.colors.orange[600], stopOpacity: 1 }}*/}
            {/*  />*/}
            {/*</linearGradient>*/}
            <radialGradient
              gradientUnits="userSpaceOnUse"
              cx="208.438"
              cy="-98.829"
              r="92.79"
              id="gradient-2"
              gradientTransform="matrix(1.877375, 1.9155, -0.830539, 0.814009, -458.272535, -308.706746)"
            >
              <stop
                offset="0"
                style={{ stopColor: theme.colors.orange[200], stopOpacity: 1 }}
              />
              <stop
                offset="1"
                style={{ stopColor: theme.colors.orange[500], stopOpacity: 1 }}
              />
              <animate
                attributeName="cy"
                from="-98.829"
                to="-140"
                dur="5.5s"
                repeatCount="indefinite"
                fill="freeze"
                values="-98.829; -140; -110; -98.829; -90; -90; -95; -98.829"
                keyTimes="0; 0.2; 0.4; 0.5; 0.6; 0.85; 0.95; 1"
                keySplines=".42 0 1 1;
                            0 0 .59 1;
                            .42 0 1 1;
                            0 0 .59 1;
                            .42 0 1 1;
                            0 0 .59 1;
                            .42 0 1 1;
                            0 0 .59 1;"
              />
            </radialGradient>
          </defs>
          {/*<path*/}
          {/*  fill="url(#gradient-2)"*/}
          {/*  d="M110.86 137.081H73.758l-.004 34.059H30.187v-69.092l80.683.354s35.614-.53 35.245-28.585c-.603-30.271-35.245-30.271-35.245-30.271s-29.977-1.237-38.265-1.237l-42.418.177L7.21 5h102.049c16.082.008 30.16 2.702 42.038 8.078 11.892 5.382 21.164 13.117 27.601 23.033 6.438 9.918 9.69 21.781 9.69 35.317 0 13.536-3.297 25.361-9.824 35.196-6.524 9.829-15.378 16.999-26.88 22.205L192.791 195h-47.102l-34.829-57.919Z"*/}
          {/*/>*/}
          <clipPath id="p.0">
            <path
              d="m0 0l424.24146 0l0 424.24146l-424.24146 0l0 -424.24146z"
              clipRule="nonzero"
            ></path>
          </clipPath>
          <g
            clipPath="url(#p.0)"
            transform="matrix(0.47143, 0, 0, 0.468912, -0.000082, 0.534044)"
          >
            <path
              // fill="#f06537"
              fill="url(#gradient-2)"
              d="m0 212.29921l0 0c0 -117.24961 95.0496 -212.29921 212.29921 -212.29921l0 0c56.305252 0 110.3044 22.367174 150.11821 62.181c39.813843 39.813828 62.181 93.81296 62.181 150.11821l0 0c0 117.24962 -95.04959 212.29921 -212.29921 212.29921l0 0c-117.24961 0 -212.29921 -95.04959 -212.29921 -212.29921z"
              fillRule="evenodd"
            />
            <path
              fill="#ffffff"
              d="m139.01686 359.34903l0 -259.20312l36.265625 0l3.734375 61.34375l1.609375 0q20.265625 -31.46875 49.859375 -49.59375q29.60936 -18.140625 65.874985 -18.140625q14.390625 0 26.65625 2.40625q12.265625 2.390625 24.53125 8.25l-10.125 37.875q-13.875 -4.796875 -23.203125 -6.921875q-9.328125 -2.140625 -24.796875 -2.140625q-29.328125 0 -56.26561 16.796875q-26.9375 16.796875 -50.40625 57.328125l0 151.99998l-43.734375 0z"
              fillRule="nonzero"
            />
          </g>
        </Icon>
        <Heading
          textTransform={'uppercase'}
          letterSpacing={0.5}
          fontSize={{ base: '2xl', md: '3xl' }}
          color={useColorModeValue('gray.800', 'gray.200')}
        >
          Sign in
        </Heading>
        <Stack
          spacing={4}
          // direction={{ base: 'column', md: 'row' }}
          w={'full'}
          as={'form'}
          noValidate
          onSubmit={e => {
            e.preventDefault()
            handleChange(e)
          }}
        >
          <FormControl id={'email'} isRequired>
            <Input
              placeholder={'your-email@example.com'}
              type={'email'}
              autoComplete={'email'}
              autoFocus
              ref={emailInput}
              onKeyUp={handleChange}
              isRequired
              color={useColorModeValue('gray.800', 'gray.200')}
              bg={useColorModeValue('gray.100', 'gray.600')}
              // rounded="none"
              borderColor={useColorModeValue('gray.100', 'gray.600')}
              _focus={{
                bg: useColorModeValue('gray.200', 'gray.800'),
                ...(validEmail ? { borderColor: validEmailColor } : {}),
              }}
            />
          </FormControl>
          <Button
            textTransform={'uppercase'}
            letterSpacing={0.25}
            // isDisabled={sent || !validEmail}
            isDisabled={sent}
            isLoading={sent === 'requesting'}
            loadingText={
              sent === 'requesting' ? 'Requesting Link' : 'Magic Link Sent'
            }
            spinnerPlacement={'end'}
            rightIcon={sent === true ? <FaCheckCircle /> : <EmailIcon />}
            bg={sent !== true ? 'orange.500' : 'green.600'}
            bgGradient={
              'linear(92.88deg, orange.500 9.16%, rgb(221,119,32) 43.89%, rgb(221,128,32) 64.72%)'
            }
            textShadow={'rgb(0 0 0 / 15%) 0px 3px 8px'}
            // rounded={'none'}
            color={'white'}
            _hover={{
              // bg: 'orange.600',
              ...useColorModeValue(
                {
                  bgGradient:
                    'linear(92.88deg, orange.600 9.16%, rgb(221,112,32) 43.89%, rgb(221,105,32) 64.72%)',
                },
                {
                  boxShadow: 'rgb(221 119 32 / 50%) 0px 1px 40px',
                }
              ),
              transition: 'all 0.1s ease 0s',
            }}
            _focus={{ bg: 'orange.600' }}
            onClick={handleChange}
          >
            {!sent ? 'Send Magic Link' : 'Magic Link Sent'}
          </Button>
        </Stack>
        {error !== '' ? (
          <Alert
            status="error"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={5}
          >
            <AlertIcon boxSize="32px" mr={0} mb={4} />
            <AlertDescription maxWidth="xs">{error}</AlertDescription>
          </Alert>
        ) : null}
        {message.length > 0 ? (
          <Alert
            status="info"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            p={5}
          >
            <AlertIcon boxSize="32px" mr={0} mb={4} />
            <AlertDescription maxWidth="xs">{message}</AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setMessage('')}
            />
          </Alert>
        ) : null}
      </Stack>
    </Flex>
  )
}
