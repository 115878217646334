import { createContext, useState, useEffect, useMemo, useContext } from 'react'
import { apiFetch } from '../auth/apiFetch.js'
import { useUser } from './UserProvider.js'

const UsersContext = createContext()

export default function UsersProvider({ children }) {
  const [userFilter, setUserFilter] = useState(undefined)
  const [users, setUsers] = useState(undefined)
  const [userLists, setUserLists] = useState({
    research: [],
    field: [],
    programming: [],
    aa: [],
    coding: [],
    dp: [],
    moderation: [],
  })
  const userAppData = useMemo(
    () => ({
      users,
      setUsers,
      userLists,
      userFilter,
      setUserFilter,
    }),
    [users, userLists, userFilter]
  )
  const { token, logout } = useUser()
  useEffect(() => {
    apiFetch('/api/users/lists', token, logout, {
      // logData: true,
      // logPerf: true,
    }).then(apiUserData => setUserLists(apiUserData))
  }, [logout, token])

  return (
    <UsersContext.Provider value={userAppData}>
      {children}
    </UsersContext.Provider>
  )
}
export function useUsers() {
  return useContext(UsersContext)
}
