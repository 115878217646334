import { extendTheme } from '@chakra-ui/react'

const styles = {
  global: props => ({
    '*': {
      touchAction: 'pan-x pan-y',
      boxSizing: 'border-box',
    },
    'html, body': {
      width: '100%',
      margin: 0,
      padding: 0,
      position: 'fixed',
      overflow: 'hidden',
      WebkitTapHighlightColor: 'transparent',
    },
    'body, button, input, optgroup, select, textarea': {
      fontFamily:
        '"Inter", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    },
    body: {
      fontSize: '16px',
      lineHeight: '1.5',
      WebkitFontSmoothing: 'antialiased',
      textRendering: 'optimizelegibility',
      // userSelect: 'none',
      textSizeAdjust: 'none',
      cursor: 'default',
      minHeight: 'unset',
      'input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: 'invert(0.5)',
      },
      '&.chakra-ui-dark, &.chakra-ui-light': {
        '& *': {},
        '&.toggle-group-tooltip': { color: 'red' },
        '.sticky-header th': {
          position: 'sticky',
          top: 0,
        },
        // '& ::-webkit-scrollbar': {
        //   width: '10px',
        //   height: '10px',
        //   backgroundColor: 'transparent',
        //   display: 'none',
        //   // backgroundColor: 'red',
        // },
        // '& ::-webkit-scrollbar-thumb': {
        //   borderRadius: '12px',
        // },
        // '& ::-webkit-scrollbar-track': {
        //   padding: '2px',
        //   backgroundColor: 'transparent',
        // },
      },
      '.chakra-modal__content-container': {
        zIndex: 9999,
      },
      '.sticky-header th.gantt': {
        maxWidth: '44px',
        minWidth: '44px',
      },
      '&.chakra-ui-light': {
        '& ::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0 ,0 ,0 ,0.05)',
        },
        '.sticky-header th': {
          background: 'red.900',
          color: 'red.50',
          verticalAlign: 'middle',
        },
        '.sticky-header th.blank-cell': {
          background: 'unset',
          color: 'unset',
        },
        '.sticky-header th.gantt': {
          background: 'gray.50',
          color: 'unset',
        },
      },
      '&.chakra-ui-dark': {
        color: 'var(--chakra-colors-whiteAlpha-800)',
        '& ::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(255,255,255,0.05)',
        },
        '.sticky-header th': {
          background: 'red.50',
          color: 'red.800',
        },
      },
      // color: mode('gray.800', 'whiteAlpha.900')(props),
      // bg: mode('gray.100', 'gray.800')(props),
      // paddingBottom: '12px',
    },
    'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video':
      {
        margin: '0px',
        padding: '0px',
        border: '0px',
        // font: 'inherit',
        verticalAlign: 'baseline',
      },
    '#root, body': {
      height: '100%',
      margin: 0,
    },
    '#root': {
      transition: 'opacity 120ms ease 0s',
    },
    '.status-container': {
      '& > *': {
        overscrollBehaviorX: 'contain',
      },
      '.job-bar-container': {
        // width: '100%',
        // overflow: 'hidden auto !important',
      },
    },
  }),
}
// const baseStyleField = props => {
//   return {
//     ...Input.baseStyle.field,
//     bg: mode('gray.100', 'gray.700')(props),
//     appearance: 'none',
//     paddingBottom: '1px',
//     lineHeight: 'normal',
//     '> option, > optgroup': {
//       bg: mode('gray.100', 'gray.700')(props),
//     },
//   }
// }
//
// const baseStyleIcon = {
//   width: '1.5rem',
//   height: '100%',
//   insetEnd: '0.5rem',
//   position: 'relative',
//   color: 'currentColor',
//   fontSize: '1.25rem',
//   _disabled: {
//     opacity: 0.5,
//   },
// }

const components = {
  // Drawer: {
  //   // setup light/dark mode component defaults
  //   baseStyle: props => ({
  //     dialog: {
  //       bg: mode('white', '#141214')(props),
  //     },
  //   }),
  // },
  Table: {
    parts: ['th', 'td', 'caption'],
    sizes: {
      xs: {
        th: {
          px: '2',
          py: '0.5',
          lineHeight: '2',
          fontSize: '0.65rem',
        },
        td: {
          px: '2',
          py: '1',
          padding: '2px 4px',
          fontSize: 'xs',
          lineHeight: '1.35',
        },
        caption: {
          px: '3',
          py: '1',
          fontSize: 'xs',
        },
      },
    },
  },
}
const colors = {
  borderColor: props => (props.colorMode === 'light' ? 'gray.100' : 'gray.700'),
}

const fonts = {
  // heading: 'Inter, Georgia, serif',
  body: '"Inter", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
}

export const theme = extendTheme({ styles, fonts, colors, components })
